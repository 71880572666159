<template>
    <div class="putin-wrap">
        <div class="putin-box">
            <div class="putin-box-item color1">
                <span id="sm" class="num">256</span>
                <span>肥料(吨)</span>
            </div>
            <div class="putin-box-item color2">
                <span id="td" class="num">86</span>
                <span>幼苗(万株)</span>
            </div>
            <div class="putin-box-item color3">
                <span id="fl"  class="num">
                    <span id="fl1">3</span>.<span id="fl2">5</span>
                </span>
                <span>农药(瓶)</span>
            </div>
        </div>
        <div class="putin-box">
            <div class="putin-box-item color4">
                <span id="hp" class="num">3938562</span>
                <span>花盆(个)</span>
            </div>
            <div class="putin-box-item color5">
                <span id="zx" class="num"><span id="zx1">7877</span>.<span id="zx2">1</span></span>
                <span>草炭土(平方米)</span>
            </div>
            <div class="putin-box-item color6">
                <span id="qt" class="num">5826</span>
                <span>穴盘(套)</span>
            </div>
            <div class="putin-box-item color7">
                <span id="yfcl" class="num">8411</span>
                <span>托架(套)</span>
            </div>
        </div>
    </div>
</template>

<script>
    import DigitRoll from '../../../extend/DigitRoll/DigitRoll'
    export default {
        name: "putin",
        activated() {
            this.$nextTick(() => {
                new DigitRoll({ container: '#sm', width: 2 }).roll(13)
                new DigitRoll({ container: '#td', width: 2 }).roll(86)
                new DigitRoll({ container: '#fl1', width: 1 }).roll(3)
                new DigitRoll({ container: '#fl2', width: 1 }).roll(5)
                new DigitRoll({ container: '#hp', width: 7 }).roll(3938562)
                new DigitRoll({ container: '#zx1', width: 4 }).roll(7877)
                new DigitRoll({ container: '#zx2', width: 1 }).roll(1)
                new DigitRoll({ container: '#qt', width: 4 }).roll(5826)
                new DigitRoll({ container: '#yfcl', width: 4 }).roll(8411)
            })
        }
    }
</script>

<style scoped lang="scss">
    .putin-wrap {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow-y: auto;
        overflow-x: hidden;
        .putin-box {
            display: flex;
            justify-content: center;
            .putin-box-item {
                padding-top: 22px;
                width: 82px;
                height: 85px;
                margin: 6px;
                background-size: cover;
                box-sizing: border-box;
                .num {
                    text-align: center;
                    font-size: 16px;
                    font-family: PangMenZhengDao;
                    font-weight: 400;
                    margin-bottom: 4px;
                    height: 18px;
                }
                > span {
                    justify-content: center;
                    display: flex;
                    color: #fff;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #FFFFFF;
                }
                &.color1 { background-image: url("../../../assets/production3/1@2x.png") }
                &.color2 { background-image: url("../../../assets/production3/2@2x.png") }
                &.color3 { background-image: url("../../../assets/production3/3@2x.png") }
                &.color4 { background-image: url("../../../assets/production3/4@2x.png") }
                &.color5 { background-image: url("../../../assets/production3/5@2x.png") }
                &.color6 { background-image: url("../../../assets/production3/6@2x.png") }
                &.color7 { background-image: url("../../../assets/production3/7@2x.png") }
            }
        }

    }
</style>
