<template>
    <div class="production-wrap">
        <div class="production-item fix-scale">
            <div class="production-item-bg" style="color: #00CCFF;">
                <div id="zdmj">3458</div>
                <span>(亩)</span>
            </div>
            <div class="production-item-desc">占地面积</div>
        </div>
        <div class="production-item fix-scale v2">
            <div class="production-item-bg" style="color: #53FEC0;">
                <div id="zpyfzx">10</div>
                <span>（亩）</span>
            </div>
            <div class="production-item-desc">组培研发中心</div>
        </div>
        <div class="production-item fix-scale v3">
            <div class="production-item-bg" style="color: #FFA63D;">
                <div id="znws">900
<!--                    <span id="znws1"/>.<span id="znws2" />-->
                </div>
                <span>（亩）</span>
            </div>
            <div class="production-item-desc">智能温室</div>
        </div>
        <div class="production-item fix-scale v4">
            <div class="production-item-bg" style="color: #FF6165;">
                <div id="gdws">
                    95
<!--                    <span id="gdws1" />.<span id="gdws2" />-->
                </div>
                <span>（亩）</span>
            </div>
            <div class="production-item-desc">高大温室</div>
        </div>
        <div class="production-item fix-scale v3">
            <div class="production-item-bg" style="color: #FFA63D;">
                <div id="zsjyzx">42</div>
                <span>（亩）</span>
            </div>
            <div class="production-item-desc">展示交易中心</div>
        </div>
        <div class="production-item fix-scale">
            <div class="production-item-bg" style="color: #00CCFF;">
                <div id="wscl">34</div>
                <span>（亩）</span>
            </div>
            <div class="production-item-desc">材料加工车间</div>
        </div>
        <div class="production-item fix-scale v4">
            <div class="production-item-bg" style="color: #FF6165;">
                <div id="zhgl">11</div>
                <span>（亩）</span>
            </div>
            <div class="production-item-desc">管理服务中心</div>
        </div>
        <div class="production-item fix-scale v2">
            <div class="production-item-bg" style="color: #53FEC0;">
                <div id="zxsq">283</div>
                <span>（项）</span>
            </div>
            <div class="production-item-desc">发明专利</div>
        </div>
    </div>
</template>

<script>
    import DigitRoll from '../../extend/DigitRoll/DigitRoll'
    export default {
        name: "production",
        activated() {
            this.$nextTick(() => {
                new DigitRoll({ container: '#zdmj', width: 4 }).roll(3458)
                new DigitRoll({ container: '#zpyfzx', width: 2 }).roll(10)
                // new DigitRoll({ container: '#znws1', width: 1 }).roll(5)
                // new DigitRoll({ container: '#znws2', width: 2 }).roll(16)
                new DigitRoll({ container: '#znws', width: 3 }).roll(900)
                // new DigitRoll({ container: '#gdws1', width: 1 }).roll(6)
                // new DigitRoll({ container: '#gdws2', width: 2 }).roll(31)
                new DigitRoll({ container: '#gdws', width: 2 }).roll(95)
                new DigitRoll({ container: '#zsjyzx', width: 2 }).roll(42)
                new DigitRoll({ container: '#wscl', width: 2 }).roll(34)
                new DigitRoll({ container: '#zhgl', width: 2 }).roll(11)
                new DigitRoll({ container: '#zxsq', width: 3 }).roll(283)
            })
        }
    }
</script>

<style scoped lang="scss">
    .production-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 10px 16px;
        box-sizing: border-box;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        .production-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80px;
            height: 100px;
            background-image: url("../../assets/production/1.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            &.v2 { background-image: url("../../assets/production/2.png"); }
            &.v3 { background-image: url("../../assets/production/3.png"); }
            &.v4 { background-image: url("../../assets/production/4.png"); }
            .production-item-bg {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 51px;
                height: 43px;
                margin-bottom: 18px;
                margin-top: 20px;
                > div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 22px;
                    font-size: 22px;
                    font-family: Arial;
                    font-weight: bold;
                    line-height: 4px;
                    margin-top: 8px;
                }
                > span {
                    display: inline-flex;
                    justify-content: center;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    transform: scale(0.7);
                    width: 90px;
                }
            }
            .production-item-desc {
                display: block;
                width: 100%;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFF;
                line-height: 16px;
                text-align: center;
            }
        }
    }
</style>
