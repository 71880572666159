<template>
    <div class="introduce-wrap fix-scale">
        <div :class="{ 'introduce-img': true, detail }" />
        <div class="introduce-text">
            天津市东信国际花卉有限公司原名大顺国际花卉股份有限公司，始建于1991年，占地面积3458亩，是以温室建造、花卉研发、生产、销售于一体的大型花卉企业，拥有一家全资子公司——天津市东信农业设施工程有限公司，主要从事温室建造及温室机械化设备开发。天津市东信国际花卉有限公司是国家农业科技园区核心区，被国际园艺生产者协会（AIPH）评选为“2015年国际最佳种植者”铜奖。公司品牌2012年被国家工商总局认定为“中国驰名商标”。
        </div>
    </div>
</template>

<script>
    export default {
        name: "introduce",
        props: {
            detail: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style scoped lang="scss">
    .introduce-wrap {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 6px;
        .introduce-img {
            height: 72px;
            background-image: url("../../assets/introduce/desc-img.png");
            background-size: cover;
            &.detail {
                height: 117px;
            }
        }
        .introduce-text {
            flex: 1;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #F9FAFA;
            line-height: 20px;
            background-color: rgba(0, 89, 52, .2);
            padding: 9px 5px;
            box-sizing: border-box;
            margin-top: 6.5px;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
</style>
