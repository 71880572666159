<template>
    <div class="layout-content-wrap">
        <div class="layout-content-left">
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale" >资源利用</span>
                <div class="layout-content-box-block">
                    <utilize class="fix-scale" />
<!--                    <collect class="fix-scale" />-->
                </div>
            </div>
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">加工净化</span>
                <div class="layout-content-box-block">
                    <filtrate class="fix-scale" />
                </div>
            </div>
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">节水成效</span>
                <div class="layout-content-box-block">
                    <investment class="fix-scale" />
                </div>
            </div>
        </div>
        <div class="layout-content-center">
<!--            <btn-group ref="btnDom" @click="videoTabEvent" :group="group" />-->
            <video class="fix-scale" ref="v0" v-show="type === '0'" src="/video/water.mp4" autoplay="autoplay" loop muted />
<!--            <video class="fix-scale" ref="v1" v-show="type === '1'" src="/video/water.mp4" autoplay="autoplay" loop muted />-->
        </div>
        <div class="layout-content-right">
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">空中悬挂浇灌</span>
                <div class="layout-content-box-block">
                    <air class="fix-scale" />
                </div>
            </div>
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">苗床喷淋浇灌</span>
                <div class="layout-content-box-block">
                    <seed class="fix-scale" />
                </div>
            </div>
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">地上潮汐灌溉</span>
                <div class="layout-content-box-block">
                    <land class="fix-scale" />
                </div>
            </div>
        </div>
        <el-dialog class="fix-scale" :visible.sync="showDialog" width="500px" :close-on-click-modal="true" top="13%">

        </el-dialog>
    </div>
</template>

<script>
    import investment from "./investment";
    import filtrate from "./filtrate";
    // import collect from "./collect";
    import air from "./air";
    import land from "./land";
    import seed from "./seed";
    // import btnGroup from "../production/production2/btnGroup";
    import utilize from "./utilize";
    export default {
        name: "WaterConservation",
        components: {
            investment,
            filtrate,
            // collect,
            air,
            land,
            seed,
            // btnGroup,
            utilize
        },
        data() {
            return {
                type: '0',
                dialogType: '',
                showDialog: false,
                selectObj: {},
                group: [
                    { name: '节水视频', type: '0' },
                    { name: '节水原理', type: '1' }
                ],
            }
        },
        activated () {
            this.$nextTick(() => {
                this.$refs['v0'] && this.$refs['v0'].play()
            })
        },
        methods: {
            videoTabEvent (v) {
                if (this.$refs['v' + this.type]) {
                    this.$refs['v' + this.type].pause()
                    this.$refs['v' + this.type].currentTime = 0
                }
                this.type = v.type
                this.$nextTick(() => {
                    if (this.$refs['v' + v.type]) {
                        this.$refs['v' + v.type].currentTime = 0
                        this.$refs['v' + v.type].play()
                    }
                })
            }
        },
        watch: {
            showDialog (nv) {
                if (!nv) {
                    this.dialogType = ''
                    this.selectObj = {}
                }
            }
        }
    }
</script>

<style scoped lang="scss">

</style>

