<template>
  <div class="utilize-wrap">
    <div class="utilize-wrap-layout">
      <div class="utilize-item">
        <div class="utilize-item-num" >
          <span id="yssjl">111</span>
        </div>
        <div class="utilize-item-bg v3"/>
        <div class="utilize-item-desc">雨水收集量</div>
        <div class="utilize-item-unit">(万吨/年)</div>
      </div>
      <div class="utilize-item">
        <div class="utilize-item-num">
          <span id="ytxcl">203</span>
        </div>
        <div class="utilize-item-bg v2"/>
        <div class="utilize-item-desc">鱼塘存量</div>
        <div class="utilize-item-unit">(万吨)</div>
      </div>
      <div class="utilize-item">
        <div class="utilize-item-num">
          <span id="hskyl">50</span>
        </div>
        <div class="utilize-item-bg"/>
        <div class="utilize-item-desc">河水用量</div>
        <div class="utilize-item-unit">(万吨/年)</div>
      </div>
      <div class="utilize-item">
        <div class="utilize-item-num">
          <span id="xscxs1">90</span>.<span id="xscxs2">5</span>
        </div>
        <div class="utilize-item-bg  v1"/>
        <div class="utilize-item-desc">加工净水</div>
        <div class="utilize-item-unit">(万吨/年)</div>
      </div>
    </div>
  </div>
</template>

<script>
import DigitRoll from "../../extend/DigitRoll/DigitRoll";
export default {
  name: "utilize",
  activated() {
    this.$nextTick(() => {
      new DigitRoll({ container: "#hskyl", width: 2 }).roll(50);
      new DigitRoll({ container: "#ytxcl", width: 3 }).roll(203);
      new DigitRoll({ container: "#yssjl", width: 3 }).roll(111);
      new DigitRoll({ container: "#xscxs1", width: 1 }).roll(90);
      new DigitRoll({ container: "#xscxs2", width: 1 }).roll(5);
    });
  },
};
</script>

<style scoped lang="scss">
.utilize-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  box-sizing: border-box;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  .utilize-wrap-layout {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .utilize-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70px;
    height: 100%;
    .utilize-item-bg {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 65px;
      height: 135px;
      background-image: url("../../assets/intelligentize/4.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      margin-bottom: 6px;
      &.v1 {
        background-image: url("../../assets/intelligentize/1.png");
      }
      &.v2 {
        background-image: url("../../assets/intelligentize/2.png");
      }
      &.v3 {
        background-image: url("../../assets/intelligentize/3.png");
      }
    }
    .utilize-item-num {
      text-align: center;
      width: 60px;
      height: 20px;
      font-size: 22px;
      font-family: Arial;
      color: rgba(0, 240, 138, 1);
      margin-bottom: 10px;
      > span {
        display: inline-flex;
        height: 20px;
        text-shadow: 1px 1px 5px #00F08A;
      }
    }
    .utilize-item-desc {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #fff;
      line-height: 16px;
      text-align: center;
    }
    .utilize-item-unit {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 12px;
    }
  }
}
</style>
