import HLS from 'hls.js'
export default {
    data () {
        return {
            hls: null
        }
    },
    created () {
        this.hls = new HLS()
        this.$nextTick(() => { this.start() })
    },
    activated() {
        this.$nextTick(() => {
            this.$refs.videoPlayer && this.$refs.videoPlayer.play()
        })
    },
    methods: {
        clickEvent () {
            this.$refs.videoPlayer.webkitRequestFullScreen()
        },
        start(){
            if (HLS.isSupported()) {
                this.hls.attachMedia(this.$refs.videoPlayer);
                this.hls.loadSource(this.url);
            }
        }
    }
}
