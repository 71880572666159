<template>
    <div class="sell-statistic-wrap">
        <div class="sell-statistic-echars" id="sellEchars"/>
    </div>
</template>

<script>
    export default {
        name: "sell",
        data() {
            return {
                xAxisData: ['天津市', '上海市', '武汉', '昆明', '保定', '沈阳', '青岛', '西安', '郑州', '合肥', '长沙', '哈尔滨', '长春'],
                xAxisData1: ['齐齐哈尔', '连云港', '西宁', '呼和浩特', '兰州', '乌鲁木齐', '洛阳', '赤峰', '哈尔滨', '银川', '保定', '南昌', '温州', '台州', '东莞', '佛山', '青岛', '宁波', '南宁', '南京', '太原', '厦门', '苏州', '合肥', '石家庄', '济南', '常州', '长沙', '大连', '无锡', '长春', '沈阳', '深圳', '福州', '西安', '重庆', '漳州', '杭州','成都', '昆明', '武汉', '郑州', '青州', '天津', '上海','北京', '广州'],
                listData1: [0.3, 0.4,0.4,0.5,0.5,0.5,0.6,0.9,1.2,1.4,1.6,1.8,2.0,2.2,2.6,2.9,3.1,3.3,3.5,3.9,4.1,4.5,4.8,5.1,5.9,6.6,7.1,7.5,8.3,8.5,8.8,9.9,10.1,10.9,11.5,12.0,12.9,13.2,13.9,14.2,14.9,15.7,16.2,17.1,18.2,19.1,20.2],
                listData: [150, 147, 130, 120, 110,96,96,96,80,78,60,56,47],
                yAxisData: ['150w', '147w', '130w', '120w', '110w','96w','96w','96w','80w','78w','60w','56w','47w'],
            }
        },
        created () {
            this.getData()
        },
        methods: {
            createEchars (id, option) {
                return this.$echarts.init(document.getElementById(id)).setOption(option)
            },
            async getData () {
                this.$nextTick(() => {
                    this.createEchars('sellEchars', {
                        tooltip: {
                            trigger: 'axis',
                            formatter:function(params){
                                return `${params[0].axisValue}\n${params[0].value}w`;
                            },
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        // legend: {
                        //     icon: "rect",
                        //     itemHeight: 15,
                        //     itemWidth: 15,
                        //     textStyle:{
                        //         color:'#fff'
                        //     }
                        // },
                        grid: {
                            top: '1%',
                            left: '6%',
                            right: '10%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: {
                            name: '',
                            type: 'value',
                            boundaryGap: [0, 0.01],
                            position: 'top',
                            axisTick:{
                                show:false // 不显示坐标轴刻度线
                            },
                            axisLine: {
                                lineStyle: {
                                    color: '#fff', //x轴的颜色
                                    width: 0, //轴线的宽度
                                }
                            },
                            splitLine: {
                                show: true,
                                lineStyle:{
                                    color: ['rgba(204, 204, 204, .3)'],
                                    width: 1,
                                    type: 'solid'
                                }
                            }
                        },
                        yAxis: [
                            {
                                type: 'category',
                                data: this.xAxisData1,
                                axisLabel: { //  如果这个字段不设置，echarts会根据屏宽及横坐标数据自动给出间隔
                                    interval: 0, // 间隔长度，可自定义（如果是时间格式，echarts会自动处理）
                                    fontSize: 12
                                    // rotate: 40 // 横坐标上label的倾斜度
                                },
                                axisTick:{
                                    show:false // 不显示坐标轴刻度线
                                },
                                axisLine: {
                                    lineStyle: {
                                        color: '#fff', //x轴的颜色
                                        width: 0, //轴线的宽度
                                    }
                                },
                                splitLine: {
                                    show: true,
                                    lineStyle:{
                                        color: ['rgba(204, 204, 204, .3)'],
                                        width: 1,
                                        type: 'solid'
                                    }
                                }
                            },
                            // {
                            //     type: 'category',
                            //     data: this.yAxisData.reverse(),
                            //     axisLabel: { //  如果这个字段不设置，echarts会根据屏宽及横坐标数据自动给出间隔
                            //         interval: 0, // 间隔长度，可自定义（如果是时间格式，echarts会自动处理）
                            //         // rotate: 40 // 横坐标上label的倾斜度
                            //     },
                            //     axisTick:{
                            //         show:false // 不显示坐标轴刻度线
                            //     },
                            //     axisLine: {
                            //         lineStyle: {
                            //             color: '#fff', //x轴的颜色
                            //             width: 0, //轴线的宽度
                            //         }
                            //     },
                            //     splitLine: {
                            //         show: true,
                            //         lineStyle:{
                            //             color: ['rgba(255, 186, 105, 0)'],
                            //             width: 0,
                            //             type: 'solid'
                            //         }
                            //     }
                            // }
                        ],
                        series: [
                            {
                                name: '销售',
                                type: 'bar',
                                data: this.listData1,
                                showBackground: true,
                                backgroundStyle: {
                                    color: 'rgba(0, 42, 26, 0)'
                                },
                                barWidth: 18,
                                // barWidth: 12,
                                itemStyle: {
                                    normal: {
                                        //这里是颜色
                                        color: 'rgba(15, 163, 121, .2)',
                                        // borderColor:'#FFBA69',
                                        borderWidth:2,
                                        shadowColor: 'rgba(83, 254, 192, 1)',
                                        shadowBlur: 12,
                                        borderColor: 'rgba(83, 254, 192, .7)',
                                    }
                                }
                            }
                        ]
                    })
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .sell-statistic-wrap {
        padding-top: 10px;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;
        .sell-statistic-echars {
            height: 2400px;
        }
    }
</style>
