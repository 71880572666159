import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入ui组件依赖
import ElementUI from 'element-ui'

import './extend/Echarts'
// 引入插件拓展
import http from "./extend/Axios/http";
import "../src/extend/Moment"

// 引入样式集合
import '@/style/theme/light/index.scss'

Vue.use(ElementUI, { size: 'small', zIndex: 3000 })
Vue.use(http, { api: 'http://175.6.124.208:30010' })

Vue.config.productionTip = false

Vue.store = store
Vue.router = router

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
