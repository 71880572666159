<template>
    <div class="layout-content-wrap">
        <div class="layout-content-left">
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale" >企业介绍</span>
                <div class="layout-content-box-block" style="padding: 6px;" @click="dialogType = 'introduce';dialogWidth = '600px';dialogTop = '10%';showDialog = true">
                    <div class="introduce-img">
                        <div class="layout-content-box-desc">天津滨海国际花卉科技园区</div>
                    </div>
                </div>
            </div>
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">生产资源</span>
                <div class="layout-content-box-block">
                    <production />
                </div>
            </div>
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">品种介绍</span>
                <div class="layout-content-box-block">
                    <flower class="fix-scale" @click="selectFlower" />
                </div>
            </div>
        </div>
        <div class="layout-content-center">
            <btn-group ref="btnDom" @click="videoTabEvent" :group="group" />
            <template>
                <video class="fix-scale" ref="v0" v-show="type === '0'" src="/video/gl.mp4" autoplay="autoplay" loop muted @click="playEvent" />
                <video class="fix-scale" ref="v1" v-show="type === '1'" src="/video/zt.mp4" autoplay="autoplay" loop muted @click="clickVideo('1')" />
                <video class="fix-scale" ref="v2" v-show="type === '2'" src="/video/yqws.mp4" autoplay="autoplay" loop muted @click="clickVideo('2')" />
                <video class="fix-scale" ref="v3" v-show="type === '3'" src="/video/eqws.mp4" autoplay="autoplay" loop muted @click="clickVideo('3')" />
                <video class="fix-scale" ref="v4" v-show="type === '4'" src="/video/bgl.mp4" autoplay="autoplay" loop muted @click="clickVideo('4')" />
                <video class="fix-scale" ref="v5" v-show="type === '5'" src="/video/gws.mp4" autoplay="autoplay" loop muted @click="clickVideo('5')" />
                <video class="fix-scale" ref="v6" v-show="type === '6'" src="/video/cf.mp4" autoplay="autoplay" loop muted @click="clickVideo('6')" />
                <video class="fix-scale" ref="v7" v-show="type === '7'" src="/video/zpsys.mp4" autoplay="autoplay" loop muted @click="clickVideo('7')" />
<!--                <video class="fix-scale" ref="v8" v-show="type === '8'" src="/video/gfsp.mp4" autoplay="autoplay" loop muted @click="playVideo" />-->
            </template>
        </div>
        <div class="layout-content-right">
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">节水降本</span>
                <div class="layout-content-box-block">
                    <cost-statistic class="fix-scale" />
                </div>
            </div>
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">产量态势</span>
                <div class="layout-content-box-block">
                    <yield-statistic class="fix-scale" />
                </div>
            </div>
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">营收规模</span>
                <div class="layout-content-box-block">
                    <revenueStatistic class="fix-scale" />
                </div>
            </div>
        </div>
        <el-dialog class="fix-scale" :title="dialogTitleMap[dialogType]" :visible.sync="showDialog" :width="dialogWidth || '500px'" :close-on-click-modal="true" :top="dialogTop || '10%'">
            <div v-if="dialogType === 'flower'" class="flower-content-layout">
                <div class="flower-content-img">
                    <img :src="selectObj.src" :alt="selectObj.alt">
                </div>
                <div class="flower-content-desc">
                    <div class="flower-content-desc-title">{{selectObj.alt}}</div>
                    <div class="flower-content-desc-text">{{selectObj.desc}}</div>
                </div>
            </div>
            <introduce v-else-if="dialogType === 'introduce'" style="width: 100%" detail />
            <div v-else-if="dialogType == '8'">
                <video class="flower-content-video" ref="videoPlayer8" src="/video/gfsp.mp4" autoplay="autoplay" loop muted @click="clickEvent" />
            </div>
            <video-detail v-else :type="type" />
        </el-dialog>
    </div>
</template>

<script>
    import introduce from "./introduce";
    import production from "./production";
    import flower from "./flower";
    import costStatistic from "./costStatistic";
    import yieldStatistic from "./yieldStatistic";
    import revenueStatistic from "./revenueStatistic";
    import btnGroup from "../production/production2/btnGroup";
    import videoDetail from "./videoDetail";
    export default {
        name: "ManagementOverview",
        components: {
            introduce,
            production,
            flower,
            costStatistic,
            yieldStatistic,
            revenueStatistic,
            btnGroup,
            videoDetail
        },
        data() {
            return {
                group: [
                    { name: '概况', type: '0' },
                    { name: '展厅', type: '1' },
                    { name: '一期温室', type: '2' },
                    { name: '二期温室', type: '3' },
                    { name: '办公楼', type: '4' },
                    { name: '高温室', type: '5' },
                    { name: '厂房', type: '6' },
                    { name: '组培实验室', type: '7' },
                    { name: '官方视频', type: '8' }
                ],
                type: "0",
                dialogType: '',
                dialogWidth: '',
                dialogTop: '',
                dialogTitleMap: {
                    introduce: '企业介绍',
                    flower: '主要花卉',
                    '0': '概况',
                    '1': '展厅',
                    '2': '一期温室',
                    '3': '二期温室',
                    '4': '办公楼',
                    '5': '高温室',
                    '6': '厂房',
                    '7': '组培实验室',
                    '8': '官方视频'
                },
                showDialog: false,
                selectObj: {}
            }
        },
        activated () {
            this.$nextTick(() => {
                this.type = '0'
                // this.$refs['v' + this.type] && this.$refs['v' + this.type].play()
                this.$refs['v' + this.type] && this.$refs['v' + this.type].pause()
                this.$refs.btnDom.active = '0'
            })
        },
        methods: {
            playVideo () {
                this.dialogType = '8'
                this.dialogWidth = '800px'
                this.dialogTop = '10%'
                this.showDialog = true
            },
            clickEvent () {
                this.$refs['videoPlayer8'].webkitRequestFullScreen()
            },
            playEvent () {
                if (this.$refs['v0'].paused) {
                    this.$refs['v0'].play()
                } else {
                    this.$refs['v0'].pause()
                }
            },
            selectFlower (img) {
                this.dialogType = 'flower'
                this.dialogWidth = '600px'
                this.dialogTop = '10%'
                this.selectObj = img
                console.log(img)
                this.showDialog = true
            },
            clickVideo (type) {
                if (type === '0') return
                this.dialogTop = '6%'
                this.dialogType = type
                this.showDialog = true
            },
            videoTabEvent (v) {
                if (v.type == '8') return this.playVideo()
                if (this.$refs['v' + this.type]) {
                    this.$refs['v' + this.type].pause()
                    this.$refs['v' + this.type].currentTime = 0
                }
                this.type = v.type
                this.$nextTick(() => {
                    if (this.$refs['v' + v.type]) {
                        this.$refs['v' + v.type].currentTime = 0
                        this.$refs['v' + v.type].play()
                    }
                })
            }
        },
        watch: {
            showDialog (nv) {
                if (!nv) {
                    this.dialogType = ''
                    this.selectObj = {}
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .introduce-img {
        position: relative;
        height: 100%;
        width: 100%;
        background-image: url("../../assets/introduce/desc-img.png");
        background-size: cover;
        background-position: center;
    }
    .flower-content-video {
        width: 100%;
    }
    .flower-content-layout {
        display: flex;
        .flower-content-img {
            > img {
                width: 227px;
                height: 100%;
                object-fit: cover;
            }
        }
        .flower-content-desc {
            margin-left: 21px;
            .flower-content-desc-title {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 24px;
                margin-bottom: 8px;
            }
            .flower-content-desc-text {
                font-size: 12px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 18px;
            }
        }
    }
</style>
