<template>
    <div class="layout-content-wrap">
        <div class="layout-content-left">
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale" >实物视频</span>
                <div class="layout-content-box-block">
                    <fact-video class="fix-scale" :type="stage || 'stage1'" />
                </div>
            </div>
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">生长参数</span>
                <div class="layout-content-box-block">
                    <parameter class="fix-scale" :type="stage || 'stage1'" />
                </div>
            </div>
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">生长趋势</span>
                <div class="layout-content-box-block">
                    <trend class="fix-scale" :type="stage || 'stage1'" />
                </div>
            </div>
        </div>
        <div class="layout-content-center img-layout">
            <btn-group ref="btnDom" @click="selectEvent" default-active-text="stage5" />
<!--            <img v-show="stage === ''" src="/gif/0.gif" alt="概览">-->
            <img v-show="stage === 'stage1'" src="/gif/1.gif" alt="阶段1">
            <img v-show="stage === 'stage2'" src="/gif/2.gif" alt="阶段2">
            <img v-show="stage === 'stage3'" src="/gif/3.gif" alt="阶段3">
            <img v-show="stage === 'stage4'" src="/gif/4.gif" alt="阶段4">
            <img v-show="stage === 'stage5'" src="/gif/5.gif" alt="催花阶段">
        </div>
        <div class="layout-content-right">
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">品种说明</span>
                <div class="layout-content-box-block">
                    <variety class="fix-scale"  @click="selectFlower" />
                </div>
            </div>
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">物候期指标</span>
                <div class="layout-content-box-block">
                    <indicator class="fix-scale" :type="stage || 'stage1'" @click="selectIndicator" />
                </div>
            </div>
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">重点关注</span>
                <div class="layout-content-box-block">
                    <attention-statistic @click="selectAttention" :type="stage || 'stage1'" />
<!--                    <attention class="fix-scale" @click="selectAttention" :type="stage || 'stage1'" />-->
                </div>
            </div>
        </div>
        <el-dialog class="fix-scale" :title="dialogTitleMap[dialogType]" :visible.sync="showDialog" :width="dialogWidth || '500px'" :close-on-click-modal="true" top="9%">
            <div v-if="dialogType === 'flower'" class="flower-content-layout">
                <div class="flower-content-img">
                    <img :src="selectObj.src" :alt="selectObj.alt">
                </div>
                <div class="flower-content-desc">
                    <div class="flower-content-desc-title">{{selectObj.alt}}</div>
                    <div class="flower-content-desc-text">{{selectObj.desc}}</div>
                </div>
            </div>
            <attention-statistic v-else-if="dialogType === 'attention'" :type="stage || 'stage1'" detail />
            <indicator v-else-if="dialogType === 'indicator'" :type="stage || 'stage1'" detail />
        </el-dialog>
    </div>
</template>

<script>
    import factVideo from "./production2/factVideo";
    // import attention from "./production2/attention";
    import trend from "./production2/trend";
    import variety from "./production2/variety";
    import parameter from "./production2/parameter";
    import indicator from "./production2/indicator";
    import btnGroup from "./production2/btnGroup";
    import attentionStatistic from "./production2/attentionStatistic";
    export default {
        name: "Production2",
        components: {
            factVideo,
            // attention,
            trend,
            variety,
            parameter,
            indicator,
            btnGroup,
            attentionStatistic
        },
        data() {
            return {
                stage: 'stage5',
                dialogWidth: '',
                dialogType: '',
                dialogTitleMap: {
                    flower: '品种说明',
                    attention: '重点关注',
                    indicator: '物候期指标'
                },
                showDialog: false,
                selectObj: {}
            }
        },
        activated() {
            // this.stage = ""
            // this.$nextTick(() => {
            //     this.$refs.btnDom.active = ''
            // })
        },
        methods: {
            selectEvent (v) {
                this.stage = v.type
            },
            selectFlower (img) {
                this.dialogType = 'flower'
                this.selectObj = img
                this.showDialog = true
            },
            selectAttention () {
                this.dialogWidth = '620px'
                this.dialogType = 'attention'
                this.showDialog = true
            },
            selectIndicator () {
                this.dialogWidth = '600px'
                this.dialogType = 'indicator'
                this.showDialog = true
            }
        },
        watch: {
            showDialog (nv) {
                if (!nv) {
                    this.dialogType = ''
                    this.dialogWidth = ''
                    this.selectObj = {}
                }
            }
        }
    }
</script>

<style lang="scss">
    .img-layout {
        img {
            height: 100%!important;
            width: auto!important;
        }
    }
    .flower-content-layout {
        display: flex;
        .flower-content-img {
            > img {
                width: 227px;
                height: 100%;
                object-fit: cover;
            }
        }
        .flower-content-desc {
            margin-left: 21px;
            .flower-content-desc-title {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 24px;
                margin-bottom: 8px;
            }
            .flower-content-desc-text {
                font-size: 12px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 18px;
            }
        }
    }
</style>

