<template>
    <div class="valuation-wrap">
        <div class="valuation-wrap-img">
            <div class="valuation-left" @click="$refs.imgDom.prev()" />
            <div class="valuation-img">
                <el-carousel ref="imgDom" indicator-position="none" arrow="never" :autoplay="true" @change="i => { index = i }">
                    <el-carousel-item v-for="(img, i) in imgList" :key="i">
                        <img class="img-style" :src="img.src" :alt="img.alt" @click="$emit('click', img)">
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="valuation-right" @click="$refs.imgDom.next()" />
        </div>
        <div class="valuation-wrap-text">
            <i class="valuation-hide" />
            <div class="valuation-center">
                <div class="valuation-center-content">
                    <div>
                        <table>
                            <tr>
                                <td>任务类型:</td>
                                <td>{{imgList[index].alt}}</td>
                                <td>责任人:</td>
                                <td>{{imgList[index].user}}</td>
                            </tr>
                            <tr>
                                <td style="width: 20px" colspan="2">任务地点:</td>
                                <td colspan="2">{{imgList[index].address}}</td>
                            </tr>
                            <tr>
                                <td>下发时间:</td>
                                <td>{{imgList[index].date}}</td>
                                <td>规定完成时间:</td>
                                <td>{{imgList[index].time}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
<!--                <div class="valuation-center-detail">查看详情</div>-->
            </div>
            <i class="valuation-hide" />
        </div>
    </div>
</template>

<script>
    export default {
        name: "valuation",
        data () {
            return {
                index: 0,
                imgList: [
                    { src: require(`../../../assets/production3/valuation1.png`), alt: '施肥任务', address: '11.2B-13bay', user: '宋爱梅', date: '2022-10-04', time: '2022-10-04' },
                    { src: require(`../../../assets/production3/valuation2.png`), alt: '换盆任务', address: '03.4C-26bay', user: '王军民', date: '2022-10-11', time: '2022-10-11' },
                    { src: require(`../../../assets/production3/valuation3.png`), alt: '修剪任务', address: '09.1A-01bay', user: '陈峰', date: '2022-11-15', time: '2022-11-25' },
                    { src: require(`../../../assets/production3/valuation4.png`), alt: '浇水预警', address: '12.3D-31bay', user: '杨淑楠', date: '2022-11-15', time: '2022-11-25' },
                    { src: require(`../../../assets/production3/valuation5.png`), alt: '设备预警', address: '04.4D-35bay', user: '梁宇', date: '2022-11-15', time: '2022-11-25' },
                ]
            }
        }
    }
</script>

<style lang="scss">
    .valuation-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        .valuation-wrap-text {
            display: flex;
            flex: 1;
            min-height: 90px;
            width: 100%;
            .valuation-hide {
                width: 16px;
                height: 100%;
            }
            .valuation-center {
                display: flex;
                flex-direction: column;
                flex: 1;
                min-height: 50px;
                background: rgba(0,255,149,0.15);

                .valuation-center-content {
                    flex: 1;
                    min-height: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-sizing: border-box;
                    color: #fff;
                    table {
                        font-size: 12px;
                    }
                    tr {
                        line-height: 25px;
                    }
                    td {
                        min-width: 70px;
                    }
                }

                .valuation-center-detail {
                    text-align: center;
                    padding: 8px;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #00FF95;
                    opacity: 0.5;
                    cursor: pointer;
                }
            }
        }
        .valuation-wrap-img {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100px;
            box-sizing: border-box;
            .el-carousel--horizontal {
                height: 100%;
                .el-carousel__container {
                    height: 100%;
                }
            }
            .img-style {
                object-fit: cover;
                height: 100%;
                width: 100%;
                border: 1px solid #43EA80;
                box-sizing: border-box;
            }
        }
        .valuation-img {
            flex: 1;
            min-width: 100px;
            height: 100px;
        }
        .valuation-left, .valuation-right {
            width: 9px;
            height: 17px;
            background-size: cover;
            cursor: pointer;
        }
        .valuation-left {
            margin-right: 7px;
            background-image: url("../../../assets/production1/prev.png");
        }
        .valuation-right {
            margin-left: 7px;
            background-image: url("../../../assets/production1/next.png");
        }
    }
</style>
