<template>
    <div class="filtrate-statistic-wrap">
        <div class="filtrate-statistic-echars" id="filtrateEchars"/>
    </div>
</template>

<script>
    export default {
        name: "filtrate",
        data() {
            return {
                xAxisData: ['2021.Q1', '2021.Q2', '2021.Q3', '2021.Q4', '2022.Q1', '2022.Q2', '2022.Q3', '2022.Q4'],
                listData: [16.8, 24.4, 32, 17.6, 19.6, 26.6, 30.6, 17.6],
            }
        },
        created () {
            this.getData()
        },
        methods: {
            createEchars (id, option) {
                return this.$echarts.init(document.getElementById(id)).setOption(option)
            },
            async getData () {
                this.$nextTick(() => {
                    this.createEchars('filtrateEchars', {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        // legend: {
                        //     icon: "rect",
                        //     itemHeight: 15,
                        //     itemWidth: 15,
                        //     textStyle:{
                        //         color:'#fff'
                        //     }
                        // },
                        grid: {
                            top: '15%',
                            left: '3%',
                            right: '5%',
                            bottom: '3%',
                            containLabel: true
                        },
                        yAxis: {
                            name: '单位（万吨）',
                            type: 'value',
                            boundaryGap: [0, 0.01],
                            axisTick:{
                                show:false // 不显示坐标轴刻度线
                            },
                            axisLine: {
                                lineStyle: {
                                    color: '#fff', //x轴的颜色
                                    width: 0, //轴线的宽度
                                }
                            },
                            splitLine: {
                                show: true,
                                lineStyle:{
                                    color: ['rgba(255, 186, 105, .1)'],
                                    width: 1,
                                    type: 'solid'
                                }
                            }
                        },
                        xAxis: {
                            type: 'category',
                            data: this.xAxisData,
                            axisLabel: { //  如果这个字段不设置，echarts会根据屏宽及横坐标数据自动给出间隔
                                interval: 0, // 间隔长度，可自定义（如果是时间格式，echarts会自动处理）
                                rotate: 40 // 横坐标上label的倾斜度
                            },
                            axisTick:{
                                show:false // 不显示坐标轴刻度线
                            },
                            axisLine: {
                                lineStyle: {
                                    color: '#fff', //x轴的颜色
                                    width: 0, //轴线的宽度
                                }
                            },
                            splitLine: {
                                show: true,
                                lineStyle:{
                                    color: ['rgba(255, 186, 105, 0)'],
                                    width: 1,
                                    type: 'solid'
                                }
                            }
                        },
                        series: [
                            {
                                name: '过滤所得',
                                type: 'bar',
                                data: this.listData,
                                barWidth: 12,
                                itemStyle: {
                                    normal: {
                                        //这里是颜色
                                        color: 'rgba(15, 163, 121, .2)',
                                        // borderColor:'#FFBA69',
                                        borderWidth:2,
                                        shadowColor: 'rgba(83, 254, 192, 1)',
                                        shadowBlur: 12,
                                        borderColor: 'rgba(83, 254, 192, .7)',
                                    }
                                }
                            },
                        ]
                    })
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .filtrate-statistic-wrap {
        padding-top: 10px;
        padding-left: 10px;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;
        .filtrate-statistic-echars {
            height: 100%;
        }
    }
</style>
