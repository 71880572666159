<template>
    <div :class="{ 'indicator-wrap': true, 'detail': detail }" @click="$emit('click', stage[type])">
        <div class="indicator-layout-left">
<!--            <div class="indicator-left" @click="$refs.imgDom.prev()" />-->
            <div class="indicator-img">
                <img class="img-style" :src="stage[type][6]" :alt="type">
<!--                <el-carousel ref="imgDom" indicator-position="none" arrow="never" :autoplay="false">-->
<!--                    <el-carousel-item v-for="(img, i) in imgList" :key="i">-->
<!--                        -->
<!--                    </el-carousel-item>-->
<!--                </el-carousel>-->
            </div>
<!--            <div class="indicator-right" @click="$refs.imgDom.next()" />-->
        </div>
        <div class="indicator-layout-right">
<!--            <template>-->
<!--                <marquee-text  class="indicator-layout-right-item" :val="`生长周期：${stage[type][0]}`"  />-->
<!--                <marquee-text  class="indicator-layout-right-item" :val="`阶段性状：${stage[type][1]}`"  />-->
<!--                <marquee-text  class="indicator-layout-right-item" :val="`毕业性状：${stage[type][2]}`"  />-->
<!--                <marquee-text  class="indicator-layout-right-item" :val="`盆径：${stage[type][3]}`"  />-->
<!--                <marquee-text  class="indicator-layout-right-item" :val="`可催花数：${stage[type][4]}`"  />-->
<!--                <marquee-text  class="indicator-layout-right-item" :val="`催花周期：${stage[type][5]}`"  />-->
<!--            </template>-->
<!--            <el-tooltip class="item" effect="dark" :content="stage[type][0]" placement="left">-->
<!--                <div class="indicator-layout-right-item common-text-overflow-line1" >生长周期：{{stage[type][0]}}</div>-->
<!--            </el-tooltip>-->
<!--            <el-tooltip class="item" effect="dark" :content="stage[type][1]" placement="left">-->
<!--                <div class="indicator-layout-right-item common-text-overflow-line1">阶段性状：{{stage[type][1]}}</div>-->
<!--            </el-tooltip>-->
<!--            <el-tooltip class="item" effect="dark" :content="stage[type][2]" placement="left">-->
<!--                <div class="indicator-layout-right-item common-text-overflow-line1">毕业性状：{{stage[type][2]}}</div>-->
<!--            </el-tooltip>-->
<!--            <el-tooltip class="item" effect="dark" :content="stage[type][3]" placement="left">-->
<!--                <div class="indicator-layout-right-item common-text-overflow-line1">盆径：{{stage[type][3]}}</div>-->
<!--            </el-tooltip>-->
<!--            <el-tooltip class="item" effect="dark" :content="stage[type][4]" placement="left">-->
<!--                <div class="indicator-layout-right-item common-text-overflow-line1">可催花数：{{stage[type][4]}}</div>-->
<!--            </el-tooltip>-->
<!--            <el-tooltip class="item" effect="dark" :content="stage[type][5]" placement="left">-->
<!--                <div class="indicator-layout-right-item common-text-overflow-line1">催花周期：{{stage[type][5]}}</div>-->
<!--            </el-tooltip>-->
            <div class="indicator-layout-right-item" >生长周期：{{stage[type][0]}}</div>
            <div class="indicator-layout-right-item">阶段性状：{{stage[type][1]}}</div>
            <div class="indicator-layout-right-item">毕业性状：{{stage[type][2]}}</div>
            <div class="indicator-layout-right-item">盆径：{{stage[type][3]}}</div>
            <div class="indicator-layout-right-item">可催花数：{{stage[type][4]}}</div>
            <div class="indicator-layout-right-item">催花周期：{{stage[type][5]}}</div>
        </div>
    </div>
</template>

<script>
    // import marquee from "../../layout/marquee";
    export default {
        name: "indicator",
        components: {
            // marqueeText: marquee
        },
        props: {
            type: {
                type: String,
                default: 'stage1'
            },
            detail: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                stage: {
                    stage0: ['6周', '2片叶小苗', '生根后到下一阶段', '7.5', '无', '无', require(`../../../assets/production2/1.png`) ],
                    stage1: ['6周', '2片叶小苗', '生根后到下一阶段', '7.5', '无', '无', require(`../../../assets/production2/1.png`)],
                    stage2: ['10周', '2层', '换盆后再长出两片成熟叶片到下一阶段', '2/14/17/19', '2朵', '催花处理（8周）', require(`../../../assets/production2/2.png`)],
                    stage3: ['10周', '3层', '再高一层到下一阶段', '12/14/17/19', '最少4朵', '催花处理（8周）', require(`../../../assets/production2/3.png`)],
                    stage4: ['10周', '4层', '再高一层到下一阶段', '12/14/17/19', '最高可达8朵', '催花处理（8周）', require(`../../../assets/production2/4.png`)],
                    stage5: ['10周', '4层', '再高一层到下一阶段', '12/14/17/19', '最高可达8朵', '催花处理（8周）', require(`../../../assets/production2/4.png`)],
                }
            }
        }
    }
</script>

<style lang="scss">
    .indicator-wrap {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;

        &.detail {
            .indicator-layout-left {
                height: 300px;
                /*width: 200px;*/
            }
            .indicator-layout-right {
                width: 200px;
                padding: 0 30px;
            }
        }

        .indicator-layout-left {
            flex: 1;
            min-width: 80px;
            height: 100%;
            display: flex;
            align-items: center;
        }

        .indicator-layout-right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            width: 140px;
            padding: 0 12px;
            .indicator-layout-right-item {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #fff;
                line-height: 20px;
                + .indicator-layout-right-item {
                    margin-top: 10px;
                }
            }
        }
        .indicator-img {
            flex: 1;
            min-width: 100px;
            height: 100%
        }
        .el-carousel--horizontal {
            height: 100%;
            .el-carousel__container {
                height: 100%;
            }
        }
        .img-style {
            object-fit: cover;
            height: 100%;
            width: 100%;
            border: 1px solid #43EA80;
            box-sizing: border-box;
        }
        .indicator-left, .indicator-right {
            width: 9px;
            height: 17px;
            background-size: cover;
            cursor: pointer;
        }
        .indicator-left {
            margin-right: 7px;
            background-image: url("../../../assets/production1/prev.png");
        }
        .indicator-right {
            margin-left: 7px;
            background-image: url("../../../assets/production1/next.png");
        }
    }
</style>
