<template>
    <div class="panel-wrap">
        <el-carousel height="200px" direction="vertical" :autoplay="false" :interval="interval" indicator-position="none">
            <el-carousel-item v-for="item in 2" :key="item">
                <div class="panel-box"  @click="$emit('click', { type })">
                    <div class="panel-box-title">
                        <div class="panel-box-text">
                            <span :class="['panel-box-icon', type]" />
                            {{textMap[type]}}
                        </div>
                        <span v-if="type !== 'worm'" class="panel-box-icon-option" />
                    </div>
                    <div class="panel-box-item"><span class="panel-box-item-icon one" />当前状态：<span>打开</span></div>
                    <div class="panel-box-item"><span class="panel-box-item-icon two" />预警状态：<span>正常</span></div>
                    <div class="panel-box-item">
                        <span class="panel-box-item-icon three" />
                        数据采集时间：{{$moment(new Date().getTime() - Math.ceil(Math.random()*10) * 60 * 60 * 1000).format('YYYY-MM-DD HH:mm:ss') }}
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
    export default {
        name: "panel",
        props: {
            type: { // ['fan', 'curtain', 'worm']
                type: String,
                default: 'fan'
            },
            interval: {
                type: Number,
                default: 3000
            }
        },
        data () {
            return {
                textMap: {
                    'fan': '风扇',
                    'curtain': '遮阳帘',
                    'worm': '虫害'
                }
            }
        }
    }
</script>

<style lang="scss">
    .panel-wrap {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        overflow-y: auto;
        .el-carousel {
            width: 345px;
            height: 199px;
        }
        .panel-box {
            width: 345px;
            height: 199px;
            background-image: url("../../../assets/production1/panel-bg.png");
            background-size: 100% 100%;
            box-sizing: border-box;
            padding: 41px 41px 0 41px;
            .panel-box-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .panel-box-text {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #3EF391;
                    line-height: 5px;
                }
            }
            .panel-box-icon {
                display: inline-block;
                width: 18px;
                height: 18px;
                background-size: cover;
                margin-right: 7.5px;
                &.fan {
                    background-image: url("../../../assets/production1/fan.png");
                }
                &.curtain {
                    background-image: url("../../../assets/production1/curtain.png");
                }
                &.worm {
                    background-image: url("../../../assets/production1/worm.png");
                }
            }
            .panel-box-icon-option {
                height: 15px;
                width: 15px;
                background-size: cover;
                background-image: url("../../../assets/production1/option.png");
                cursor: pointer;
            }

            .panel-box-item {
                display: flex;
                align-items: center;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #F9FAFA;
                line-height: 5px;
                margin-top: 10px;
                > span {
                    color: #3EF391;
                }
                .panel-box-item-icon {
                    display: inline-block;
                    height: 15px;
                    width: 15px;
                    background-size: cover;
                    margin-right: 8.5px;
                    &.one {
                        background-image: url("../../../assets/production1/one.png");
                    }
                    &.two {
                        background-image: url("../../../assets/production1/two.png");
                    }
                    &.three {
                        background-image: url("../../../assets/production1/three.png");
                    }
                }
            }
        }
    }
</style>
