<template>
    <div class="layout-content-wrap">
        <div class="layout-content-left">
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale" >温湿度感知</span>
                <div class="layout-content-box-block">
                    <temperature class="fix-scale" @click="clickEvent" />
                </div>
            </div>
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">风扇控制</span>
                <div class="layout-content-box-block">
                    <panel class="fix-scale" type="fan" @click="clickEvent" :interval="3000" />
                </div>
            </div>
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">遮阳帘</span>
                <div class="layout-content-box-block">
                    <panel class="fix-scale" type="curtain" @click="clickEvent" :interval="6000" />
                </div>
            </div>
        </div>
        <div class="layout-content-center">
            <btn-group ref="btnDom" @click="selectEvent" :group="group1" />
            <btn-group ref="btn1Dom" v-show="type === '1'" @click="subSelectEvent" :group="group2" mode="horizontal" :default-active="false" />
            <btn-group ref="btn2Dom" v-show="type === '2'" @click="subSelectEvent" :group="group3" mode="horizontal" :default-active="false" />
            <video class="fix-scale" ref="v0" v-show="type === '0'" src="/video/production1/0.mp4" autoplay="autoplay" loop muted @click="playEvent" />
            <video class="fix-scale" ref="v1" v-show="type === '1' && subType === ''" src="/video/production1/1.mp4" autoplay="autoplay" loop muted />
            <video class="fix-scale" ref="v2" v-show="type === '2' && subType === ''" src="/video/production1/2.mp4" autoplay="autoplay" loop muted />
            <video class="fix-scale" ref="v10" v-show="type === '1' && subType === '0'" src="/video/production1/1-0.mp4" autoplay="autoplay" loop muted />
            <video class="fix-scale" ref="v11" v-show="type === '1' && subType === '1'" src="/video/production1/1-1.mp4" autoplay="autoplay" loop muted />
            <video class="fix-scale" ref="v20" v-show="type === '2' && subType === '0'" src="/video/production1/2-0.mp4" autoplay="autoplay" loop muted />
            <video class="fix-scale" ref="v21" v-show="type === '2' && subType === '1'" src="/video/production1/2-1.mp4" autoplay="autoplay" loop muted />
            <div v-show="type === '2' && subType === '2'" class="fix-scale avg-layout">
                <div class="avg-layout-left">
                    <img src="../../assets/production1/pro-center.jpg" alt="avg-map">
                    <i class="point" style="top: 171px;left: 186px;" />
                    <i class="point" style="top: 222px;left: 233px;" />
                    <i class="point" style="top: 325px;left: 142px;" />
                    <i class="point" style="bottom: 222px;left: 132px;" />
                    <i class="point" style="top: 210px;right: 154px;" />
                    <i class="point" style="top: 325px;right: 304px;" />
                    <i class="point" style="top: 325px;right: 156px;" />
                    <i class="point" style="bottom: 221px;right: 269px;" />
                </div>
                <div class="avg-layout-right">
                    <div class="avg-layout-right-header">
                        <div class="avg-layout-right-header-left">
                            <div class="avg-layout-right-header-left-item"><span>23</span><span>在线</span></div>
                            <div class="avg-layout-right-header-left-item cldzcl"><span>15</span><span>充电中</span></div>
                            <div class="avg-layout-right-header-left-item mzcl"><span>5</span><span>满载</span></div>
                            <div class="avg-layout-right-header-left-item lxcl"><span>1</span><span>离线</span></div>
                            <div class="avg-layout-right-header-left-item wxzcl"><span>1</span><span>维修中</span></div>
                            <div class="avg-layout-right-header-left-item kzcl"><span>3</span><span>空载</span></div>
                        </div>
                        <div class="avg-layout-right-header-right">
                            <div class="avg-layout-right-header-right-row">
                                <div class="avg-layout-right-header-right-row-cel"><span>车辆编号:</span>001</div>
                                <div class="avg-layout-right-header-right-row-cel"><span>状态信息:</span>满载</div>
                            </div>
                            <div class="avg-layout-right-header-right-row">
                                <div class="avg-layout-right-header-right-row-cel"><span>车辆状态:</span>运行中</div>
                                <div class="avg-layout-right-header-right-row-cel"><span>电量信息:</span>82%</div>
                            </div>
                            <div class="avg-layout-right-header-right-row">
                                <div class="avg-layout-right-header-right-row-cel"><span>位置信息:</span>在轨</div>
                                <div class="avg-layout-right-header-right-row-cel"><span>错误编码:</span>无</div>
                                <div class="avg-layout-right-header-right-row-cel"><span>错误原因:</span>无</div>
                            </div>
                        </div>
                    </div>
                    <div class="avg-layout-right-content">
                        <video class="fix-scale" v-show="type === '2' && subType === '2' && ctype === '2'" ref="v22" src="/video/production1/2-3.mp4" autoplay="autoplay" loop muted @click="$refs.v22.webkitRequestFullScreen()" />
                        <video class="fix-scale" v-show="type === '2' && subType === '2' && ctype === '3'" ref="v23" src="/video/production1/2-2.mp4" autoplay="autoplay" loop muted @click="$refs.v22.webkitRequestFullScreen()" />
                    </div>
                    <div class="avg-layout-right-footer">
                        <div class="avg-layout-right-footer-gif" @click="ctype = '2'">
                            <img src="/gif/car2.gif" alt="avg-map">
                        </div>
                        <div class="avg-layout-right-footer-gif" @click="ctype = '3'">
                            <img src="/gif/car1.gif" alt="avg-map">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-content-right">
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">视频监控</span>
                <div class="layout-content-box-block">
                    <monitoring class="fix-scale" />
                </div>
            </div>
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">虫害详情</span>
                <div class="layout-content-box-block">
                    <panel class="fix-scale" type="worm" :interval="4000" />
                </div>
            </div>
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">预警数据</span>
                <div class="layout-content-box-block">
                    <warn class="fix-scale" />
                </div>
            </div>
        </div>
        <el-dialog class="fix-scale" :title="dialogTitleMap[dialogType]" :visible.sync="showDialog" :width="dialogWidth || '800px'" :close-on-click-modal="true" top="6%">
            <temperatureStatistic v-if="['KQWD', 'KQSD', 'TRSD', 'TRWD'].includes(dialogType)" :type="dialogType" />
            <ctrlStatistic v-if="['fan', 'worm', 'curtain'].includes(dialogType)" :type="dialogType" />
        </el-dialog>
    </div>
</template>

<script>
    import temperature from "./production1/temperature";
    import panel from "./production1/panel";
    import monitoring from "./production1/monitoring";
    import warn from "./production1/warn";
    import temperatureStatistic from "./production1/temperatureStatistic";
    import ctrlStatistic from "./production1/ctrlStatistic";
    import btnGroup from "./production2/btnGroup";
    export default {
        name: "Production1",
        components: {
            temperature,
            panel,
            monitoring,
            warn,
            temperatureStatistic,
            ctrlStatistic,
            btnGroup
        },
        data() {
            return {
                type: '0',
                subType: '',
                ctype: '2',
                dialogType: '',
                dialogWidth: '',
                showDialog: false,
                selectObj: {},
                group1: [
                    { name: '概况', type: '0' },
                    { name: '一期温室', type: '1' },
                    { name: '二期温室', type: '2' }
                ],
                group2: [
                    { name: '水肥', type: '0' },
                    { name: '通风', type: '1' }
                ],
                group3: [
                    { name: '水肥', type: '0' },
                    { name: '通风', type: '1' },
                    { name: 'AGV', type: '2' }
                ],
                dialogTitleMap: {
                    KQWD: '空气温度',
                    KQSD: '空气湿度',
                    TRSD: '土壤湿度',
                    TRWD: '土壤温度',
                    fan: '风扇控制',
                    worm: '虫害详情',
                    curtain: '遮阳帘控制',
                }
            }
        },
        activated () {
            this.selectEvent({ type: '0' })
            this.$nextTick(() => {
                this.$refs.btnDom.active = '0'
            })
        },
        methods: {
            playEvent () {
                if (this.$refs['v0'].paused) {
                    this.$refs['v0'].play()
                } else {
                    this.$refs['v0'].pause()
                }
            },
            selectEvent (v) {
                this.type = v.type
                this.subType = ''
                this.$nextTick(() => {
                    if (this.$refs['v' + v.type + this.subType]) {
                        this.$refs['v' + v.type + this.subType].currentTime = 0
                        this.$refs['v' + v.type + this.subType].play()
                    }
                })
                this.$nextTick(() => {
                    this.$refs.btn1Dom && (this.$refs.btn1Dom.active = '')
                    this.$refs.btn2Dom && (this.$refs.btn2Dom.active = '')
                })
            },
            subSelectEvent (v) {
                this.subType = v.type || ''
                this.ctype = '2'
                this.$nextTick(() => {
                    if (this.$refs['v' + this.type + this.subType]) {
                        this.$refs['v' + this.type + this.subType].currentTime = 0
                        this.$refs['v' + this.type + this.subType].play()
                    }
                })
            },
            clickEvent (obj) {
                this.dialogType = obj.type
                this.dialogWidth = obj.width
                this.showDialog = true
            }
        },
        watch: {
            showDialog (nv) {
                if (!nv) {
                    this.dialogType = ''
                    this.selectObj = {}
                }
            }
        }
    }
</script>

<style lang="scss">
    .avg-layout {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        box-shadow: inset 0px 0px 16px 0px #16F4B3;

        .avg-layout-left {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 100px;
            flex: 1;
            height: calc(100% - 30px);
            background-color: #bdbdbd;
            padding: 0 6px;
            box-sizing: border-box;
            margin: 15px 10px 15px 15px;
            overflow: hidden;
            &.test {
                width: 599px;
                height: 659px;
                > img {
                    height: 528.24px;
                    width: 587px;
                    object-fit: cover;
                }
            }
            > img {
                /*height: 100%;*/
                width: 100%;
                object-fit: cover;
            }
            .point {
                position: absolute;
                height: 10px;
                width: 10px;
                background-color: #2CA2F2;
                background-image: url("../../assets/production1/point.gif");
                background-size: cover;
                border-radius: 50%;
            }
        }
        .avg-layout-right {
            display: flex;
            flex-direction: column;
            min-width: 100px;
            flex: 1;
            height: 100%;
            margin-right: 15px;
            overflow: hidden;

            .avg-layout-right-header {
                display: flex;
                height: 120px;
                box-sizing: border-box;
                padding: 15px 0;
                > div {
                    flex: 1;
                    min-width: 50px;
                }
                .avg-layout-right-header-left {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    border-right: 1px solid rgba(20, 174, 91, .4);
                    margin-right: 10px;
                    .avg-layout-right-header-left-item {
                        width: 32%;
                        background-image: url("../../assets/production1/zxcl.png");
                        background-size: 26px 26px;
                        background-repeat: no-repeat;
                        background-position: 1px 5px;
                        padding-left: 30px;
                        box-sizing: border-box;
                        cursor: pointer;
                        &.cdzcl { background-image: url("../../assets/production1/cdzcl.png"); }
                        &.mzcl { background-image: url("../../assets/production1/mzcl.png"); }
                        &.kzcl { background-image: url("../../assets/production1/kzcl.png"); }
                        &.wxzcl { background-image: url("../../assets/production1/wxzcl.png"); }
                        &.lxcl { background-image: url("../../assets/production1/lxcl.png"); }

                        > span {
                            display: block;
                            font-size: 14px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: #FFF;
                            line-height: 18px;
                        }
                        :last-child {
                            color: rgba(135, 153, 192, 1);
                            font-size: 12px;
                        }
                    }
                }
                .avg-layout-right-header-right {
                    .avg-layout-right-header-right-row {
                        width: 100%;
                        height: 33%;
                        display: flex;
                        align-items: center;
                        color: #fff;
                        .avg-layout-right-header-right-row-cel {
                            width: 33%;
                            font-size: 12px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: #fff;
                            > span {
                                color: #00D87F;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
            .avg-layout-right-content {
                min-height: 200px;
                flex: 1;
                > video {
                    height: 100%;
                }
            }
            .avg-layout-right-footer {
                display: flex;
                height: 120px;
                margin-top: 5px;
                .avg-layout-right-footer-gif {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex: 1;
                    min-width: 50px;
                    cursor: pointer;
                    overflow: hidden;
                    > img {
                        height: 100%;
                        /*width: 100%;*/
                        object-fit: cover;
                    }
                    + .avg-layout-right-footer-gif {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
</style>
