<template>
    <div class="layout-content-wrap">
        <div class="layout-content-left">
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale" >田块统计</span>
                <div class="layout-content-box-block">
                    <fields class="fix-scale" />
                </div>
            </div>
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">农事统计</span>
                <div class="layout-content-box-block">
                    <farming class="fix-scale" />
                </div>
            </div>
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">投入品统计</span>
                <div class="layout-content-box-block">
                    <putin class="fix-scale" />
                </div>
            </div>
        </div>
<!--        <div class="layout-content-center">-->
<!--            <div class="map-style old">-->
<!--                <follow ref="follow" :width="300">-->
<!--                    <div class="follow-content">-->
<!--                        <i class="close" @click="$refs.follow.hide()" />-->
<!--                        <div class="follow-content-item">-->
<!--                            <span class="title">负责人:</span>-->
<!--                            <div>{{selectObj.user}}</div>-->
<!--                        </div>-->
<!--                        <div class="follow-content-item-img">-->
<!--                            <img :src="selectObj.tx" alt="头像">-->
<!--                        </div>-->
<!--                        <div class="follow-content-item">-->
<!--                            <span class="title">田块:</span>-->
<!--                            <div>{{selectObj.address}}</div>-->
<!--                        </div>-->
<!--                        <div class="follow-content-item-img">-->
<!--                            <img :src="selectObj.tk" alt="田块">-->
<!--                        </div>-->

<!--                        <div class="follow-content-item">-->
<!--                            <span class="title">日期:</span>-->
<!--                            <div>{{selectObj.date}}</div>-->
<!--                        </div>-->
<!--                        <div class="follow-content-item">-->
<!--                            <span class="title">品种:</span>-->
<!--                            <div>{{selectObj.tree}}</div>-->
<!--                        </div>-->
<!--                        <div class="follow-content-item-img">-->
<!--                            <img :src="selectObj.pz" alt="品种">-->
<!--                        </div>-->
<!--                        <div class="follow-content-item" style="display: block;">-->
<!--                            <span class="title">农事信息:</span>-->
<!--                            <div v-if="selectObj.info">-->
<!--                                <div v-for="(item, i) in selectObj.info.split(',')" :key="i" style="padding: 6px 0 0 20px;">-->
<!--                                    {{item}}-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </follow>-->
<!--                <i class="map-point" style="top: 20%;left: 44%;" @click="ponitEvent($event, 0)" />-->
<!--                <i class="map-point" style="top: 24%;left: 40%;" @click="ponitEvent($event, 1)" />-->
<!--                <i class="map-point" style="top: 44%;left: 39%;" @click="ponitEvent($event, 2)" />-->
<!--                <i class="map-point" style="top: 35%;left: 38%;" @click="ponitEvent($event, 3)" />-->
<!--                &lt;!&ndash;                <i class="map-point" style="top: 48%;left: 43%;" @click="ponitEvent($event, 4)" />&ndash;&gt;-->
<!--            </div>-->
<!--        </div>-->
        <div class="layout-content-center-box-layout">
            <div class="layout-content-center-box">
                <div class="layout-content-center-item text-plane">
                    <div class="text-plane-title">作物检索</div>
                    <div class="text-plane-content">
                        <div class="text-plane-content-srcoll">
                            <div v-for="(item, i) in btn" :key="i" :class="['text-plane-btn', curIndex === i ? 'active' : '']" @click="btnSelect(i)">{{item}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="layout-content-center-box-img">
<!--                <img :src="require('/public/image/btn (1).png')" alt="111">-->
                <img v-for="(item, i) in btn" v-show="curIndex === i" :key="i" :src="require(`/public/image/btn (${i + 1}).png`)" :alt="item">
            </div>
            <div class="layout-content-center-box-desc">
                <div class="follow-content">
<!--                    <i class="close" @click="$refs.follow.hide()" />-->
                    <div class="follow-content-item">
                        <span class="title">负责人:</span>
                        <div>{{selectObj.user}}</div>
                    </div>
                    <div class="follow-content-item-img">
                        <img :src="selectObj.tx" alt="头像">
                    </div>
                    <div class="follow-content-item">
                        <span class="title">田块:</span>
                        <div>{{selectObj.address}}</div>
                    </div>
                    <div class="follow-content-item-img">
                        <img :src="selectObj.tk" alt="田块">
                    </div>

                    <div class="follow-content-item">
                        <span class="title">日期:</span>
                        <div>{{selectObj.date}}</div>
                    </div>
                    <div class="follow-content-item">
                        <span class="title">品种:</span>
                        <div>{{btn[curIndex]}}</div>
                    </div>
                    <div class="follow-content-item-img">
                        <img :src="selectObj.pz" alt="品种">
                    </div>
                    <div class="follow-content-item" style="display: block;">
                        <span class="title">农事信息:</span>
                        <div v-if="selectObj.info">
                            <div v-for="(item, i) in selectObj.info.split(',')" :key="i" style="padding: 6px 0 0 20px;">
                                {{item}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-content-right">
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">在途农事</span>
                <div class="layout-content-box-block">
                    <process class="fix-scale" />
                </div>
            </div>
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">农事评价</span>
                <div class="layout-content-box-block">
                    <valuation class="fix-scale" />
                </div>
            </div>
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">农事预警</span>
                <div class="layout-content-box-block">
                    <warning class="fix-scale" />
                </div>
            </div>
        </div>
        <el-dialog class="fix-scale" :visible.sync="showDialog" width="500px" :close-on-click-modal="true" top="13%">

        </el-dialog>
    </div>
</template>

<script>
    import warning from "./production3/warning";
    import valuation from "./production3/valuation";
    import process from "./production3/process";
    import fields from "./production3/fields";
    import farming from "./production3/farming";
    import putin from "./production3/putin";
    // import follow from "../../components/follow/follow";
    export default {
        name: "Production3",
        components: {
            warning,
            valuation,
            process,
            fields,
            farming,
            putin,
            // follow
        },
        data() {
            return {
                curIndex: 0,
                searchVal: '',
                dialogType: '',
                showDialog: false,
                selectObj: {},
                btn: ['白掌', '宝莲灯', '比卡秋', '多肉', '矾根', '粉冠军', '粉皇冠', '龟背竹', '海芋', '蝴蝶兰', '火鸟', '美酒白掌', '魅力之星', '琴叶榕', '青苹果', '散尾葵', '生菜', '特伦萨', '跳舞兰', '新飞羽', '棕竹'],
                point: [
                    { address: '05.1C-22bay', user: '刘伟华', date: '2022-11-23', info: '1、组织巡田,2、组织杀虫,3、设备检查', tree: '宝莲灯', tx: require('../../assets/production3/follow-img/tx1.png'), tk: require('../../assets/production3/follow-img/tk1.png'), pz: require('../../assets/production3/follow-img/pz1.png') },
                    { address: '10.4A-6bay', user: '刘小伟', date: '2022-11-23', info: '1、组织巡田,2、设备检查', tree: '宝莲灯', tx: require('../../assets/production3/follow-img/tx2.png'), tk: require('../../assets/production3/follow-img/tk2.png'), pz: require('../../assets/production3/follow-img/pz2.png') },
                    { address: '09.6D-37bay', user: '吕红', date: '2022-11-21', info: '1、组织巡田,2、组织杀虫,3、设备检查', tree: '宝莲灯', tx: require('../../assets/production3/follow-img/tx.png'), tk: require('../../assets/production3/follow-img/tk.png'), pz: require('../../assets/production3/follow-img/pz.png') },
                    { address: '10.4A-6bay', user: '陈峰', date: '2022-11-23', info: '1、组织杀虫,2、设备检查', tree: '宝莲灯', tx: require('../../assets/production3/follow-img/tx3.png'), tk: require('../../assets/production3/follow-img/tk4.png'), pz: require('../../assets/production3/follow-img/pz3.png') },
                    { address: '05.1C-22bay', user: '王军民', date: '2022-11-24', info: '1、组织巡田,2、组织杀虫,3、设备检查', tree: '宝莲灯', tx: require('../../assets/production3/follow-img/tx4.png'), tk: require('../../assets/production3/follow-img/tk4.png'), pz: require('../../assets/production3/follow-img/pz4.png') },
                ]
            }
        },
        created() {
            this.selectObj = this.point[this.curIndex]
        },
        methods: {
            btnSelect (i) {
              if (this.curIndex === i) return
              this.curIndex = i
              this.selectObj = this.point[this.curIndex] || this.point[0]
            },
            ponitEvent (e, i) {
                this.selectObj = this.point[i]
                this.$refs.follow.show(e)
            }
        },
        watch: {
            showDialog (nv) {
                if (!nv) {
                    this.dialogType = ''
                    this.selectObj = {}
                }
            }
        }
    }
</script>

<style lang="scss">
    .layout-content-center-box-layout {
        padding: 0 2.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex: 1;
        min-width: 200px;
        overflow: hidden;
        box-sizing: border-box;
        .layout-content-center-box-desc {
            width: 260px;
            height: 100%;
        }
        .layout-content-center-box-img {
            height: 100%;
            min-width: 100px;
            flex: 1;
            margin: 0 5px;
            > img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .layout-content-center-box {
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 100%;
            .layout-content-center-item {
                height: 100%;
                flex: 1;
                min-width: 100px;
                border: 1px solid transparent;
                box-sizing: border-box;
                > img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
                + .layout-content-center-item {
                    margin-left: 3px;
                }
                &.text-plane {
                    width: 240px;
                    flex: none;
                    border-radius: 8px;
                    background: rgba(0,39,18,0.3);
                    border: 1px solid #18714D;
                    box-sizing: border-box;
                    padding: 1px;
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    .text-plane-content {
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        min-height: 100px;
                    }
                    .text-plane-content-desc {
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FFF;
                        padding: 15px 0;
                    }
                    .text-plane-content-srcoll {
                        flex: 1;
                        min-height: 100px;
                        overflow-x: hidden;
                        overflow-y: auto;
                        &::-webkit-scrollbar { width: 2px; height: 2px; background-color: transparent;}
                        &::-webkit-scrollbar-track { width: 2px; background-color: rgba(255,255, 255, .1); }
                        &::-webkit-scrollbar-thumb { border-radius: 1px;background-color: rgba(255,255, 255, .3); }
                    }
                    > .text-plane-title {
                        position: relative;
                        padding: 6px 0 6px 30px;
                        box-sizing: border-box;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FFF;
                        border-radius: 4px;
                        background: linear-gradient(90deg, rgba(11, 186, 113, 0.5) 0%, rgba(75, 255, 179, 0.06) 50%, rgba(12, 185, 112, 0.01) 100%);
                        &:before {
                            position: absolute;
                            content: '';
                            height: 12px;
                            width: 12px;
                            left: 10px;
                            top: 7px;
                            background-image: url("../../assets/production3/text-icon.png");
                            background-size: contain;
                        }
                    }

                    .text-plane-content {
                        padding: 8px 10px;
                    }
                    .text-plane-icon {
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        background: #00D87F;
                        border-radius: 50%;
                        margin-right: 8px;
                    }
                    .text-plane-item-color {
                        width: 150px;
                        height: 10px;
                        background: #00D87F;
                        margin-left: 4px;
                    }
                    .text-plane-btn {
                        width: 210px;
                        height: 50px;
                        line-height: 50px;
                        background-image: url("../../assets/production3/btn-c.png");
                        background-size: cover;
                        text-align: center;
                        font-size: 22px;
                        color: rgba(164, 254, 182, 1);
                        cursor: pointer;
                        &.active {
                            background-image: url("../../assets/production3/btn-a.png");
                        }
                        + .text-plane-btn {
                            margin-top: 20px;
                        }
                    }
                    .text-plane-item {
                        position: relative;
                        padding: 8px 0;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FFFFFF;
                        height: 25px;
                        display: flex;
                        align-items: center;
                        + .text-plane-item {
                            margin-top: -1px;
                        }
                        &:before {
                            position: absolute;
                            content: '';
                            height: 1px;
                            width: 160px;
                            left: 0;
                            top: 0;
                            background: linear-gradient(90deg, #15B0F8 0%, rgba(2,167,240,0) 100%);
                        }
                        &:after {
                            position: absolute;
                            content: '';
                            height: 1px;
                            width: 160px;
                            left: 0;
                            bottom: 0;
                            background: linear-gradient(90deg, #15B0F8 0%, rgba(2,167,240,0) 100%);
                        }
                    }
                }
            }
        }
    }
    .map-style {
        height: 100%;
        width: 100%;
        position: relative;
        background-size: auto 100%;
        background-position: center;
        &.old {
            background-image: url("../../assets/production3/map.jpg");
        }
        .map-point {
            position: absolute;
            width: 32px;
            height: 32px;
            background-image: url("../../assets/production3/point.png");
            background-size: cover;
            cursor: pointer;
            transition: ease-in-out .2s;
            &:hover {
              transform: scale(1.5);
            }
        }
    }
    .follow-content {
        height: 100%;
        position: relative;
        padding: 20px 50px;
        background: rgba(0,39,18,1);
        box-sizing: border-box;
        border: 1px solid #127749;
        overflow-x: hidden;
        overflow-y: auto;
        border-radius: 8px;
        .close {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            right: 8px;
            top: 8px;
            background-image: url("../../assets/production3/follow-close.png");
            background-size: cover;
            cursor: pointer;
        }
        .follow-content-item {
            display: inline-flex;
            align-items: flex-start;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            padding: 16px 6px;
            box-sizing: border-box;
            .title {
                padding: 0 10px 0 15px;
                position: relative;
                color: #D9FFEE;
                &::before {
                    content: '';
                    position: absolute;
                    width: 9px;
                    height: 13px;
                    left: 2px;
                    top: 1px;
                    background-image: url("../../assets/production3/follow-title.png");
                    background-size: cover;
                }
            }
            > div {
                flex: 1;
                /*color: #74FF6D ;*/
            }
        }
        .follow-content-item-img {
            display: flex;
            justify-content: center;
            img {
                width: 110px;
                border: 1px solid #00D47D;
                object-fit: cover;
            }
        }
    }
</style>

