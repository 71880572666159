<template>
    <div class="warning-wrap">
        <vue-seamless-scroll :data="processList" :class-option="{ step: 0.2 }" >
            <div class="warning-item warn" v-for="(item, i) in processList" :key="i">
                <span class="warning-item-icon">{{item.user}}</span>
                <div class="warning-item-content">
                    <i class="warning-item-content-icon"/>{{item.type}} <span class="status">异常</span>
                </div>
                <span class="warning-item-content-time">
                数据采集时间：{{$moment(new Date().getTime() - Math.ceil(Math.random()*10) * 8 * 60 * 60 * 1000).format('YYYY-MM-DD')}}
            </span>
            </div>
        </vue-seamless-scroll>
    </div>
</template>

<script>
    import vueSeamlessScroll from 'vue-seamless-scroll'
    export default {
        name: "warning",
        components: {
            vueSeamlessScroll
        },
        data () {
            return {
                processList: [
                    { type: '施肥预警', address: '11.2C-25bay', user: '宋爱梅', date: '2022.11.02', time: '2022-11-25' },
                    { type: '换盆预警', address: '03.4A-02bay', user: '王军民', date: '2022.11.06', time: '2022-11-25' },
                    { type: '修剪预警', address: '09.1D-33bay', user: '陈峰', date: '2022.11.03', time: '2022-11-25' },
                    { type: '浇水预警', address: '12.3A-06bay', user: '杨淑楠', date: '2022.11.08', time: '2022-11-25' },
                    { type: '设备预警', address: '07.3C-27bay', user: '吴耀华', date: '2022.11.01', time: '2022-11-25' },
                    { type: '杀虫预警', address: '04.4B-19bay', user: '梁宇', date: '2022.11.04', time: '2022-11-25' },
                ]
            }
        }
    }
</script>

<style scoped lang="scss">
    .warning-wrap {
        display: flex;
        height: 100%;
        flex-direction: column;
        /*overflow-y: auto;*/
        overflow-y: hidden;
        overflow-x: hidden;
        box-sizing: border-box;
        padding: 9px 10px;
        .warning-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            flex: 1;
            background-color: rgba(0, 104, 22, 0.15);
            box-sizing: border-box;
            padding: 25px 10px 10px;
            /*+ .warning-item {*/
            /*    margin-top: 6px;*/
            /*}*/
            margin-top: 6px;
            &.warn {
                .warning-item-content {
                    > .status {
                        color: rgba(255, 59, 59, 1);
                    }

                    .warning-item-content-icon {
                        background-image: url("../../../assets/production3/static-w.png");
                    }
                }
            }
            .warning-item-icon {
                display: inline-flex;
                align-items: center;
                position: absolute;
                background-image: url("../../../assets/production3/process-item-1.png");
                background-repeat: no-repeat;
                background-size: contain;
                left: 0;
                top: 0;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                height: 18px;
                width: 60px;
                padding-left: 10px;
                box-sizing: border-box;
                cursor: pointer;
            }
            /*&::after {*/
            /*    position: absolute;*/
            /*    content: '查看详情';*/
            /*    right: 10px;*/
            /*    top: 7px;*/
            /*    font-size: 12px;*/
            /*    font-family: Microsoft YaHei;*/
            /*    font-weight: 400;*/
            /*    color: #00D87F;*/
            /*    cursor: pointer;*/
            /*}*/

            .warning-item-content {
                display: flex;
                align-items: center;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;

                > .status {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #00D87F;
                    margin-left: 5px;

                }

                .warning-item-content-icon {
                    display: inline-block;
                    height: 22px;
                    width: 18px;
                    background-image: url("../../../assets/production3/static-c.png");
                    background-size: contain;
                    background-repeat: no-repeat;
                    margin-right: 8px;
                }
            }

            .warning-item-content-time {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
</style>
