<template>
    <div class="cost-statistic-wrap">
        <div class="cost-statistic-echars" id="costEchars"/>
    </div>
</template>

<script>
    export default {
        name: "costStatistic",
        data() {
            return {
                xAxisData: ['2021 Q1', '2021 Q2', '2021 Q3', '2021 Q4', '2022 Q1', '2022 Q2', '2022 Q3', '2022 Q4'],
                listData: [117.6, 170.8, 224, 123.2,137.2,186.2,214.2,123.2]
            }
        },
        created () {
            this.getData()
        },
        methods: {
            createEchars (id, option) {
                return this.$echarts.init(document.getElementById(id)).setOption(option)
            },
            async getData () {
                this.$nextTick(() => {
                    this.createEchars('costEchars', {
                        tooltip: {
                            trigger: 'axis'
                        },
                        xAxis: {
                            name: '',
                            type: 'category',
                            data: this.xAxisData,
                            axisLabel: { //  如果这个字段不设置，echarts会根据屏宽及横坐标数据自动给出间隔
                                interval: 0, // 间隔长度，可自定义（如果是时间格式，echarts会自动处理）
                                rotate: 40 // 横坐标上label的倾斜度
                            },
                            axisTick:{
                                show:false // 不显示坐标轴刻度线
                            },
                            axisLine: {
                                lineStyle: {
                                    color: '#fff', //x轴的颜色
                                    width: 0, //轴线的宽度
                                }
                            },
                            splitLine: {
                                show: true,
                                lineStyle:{
                                    color: ['#1e8f63'],
                                    width: 1,
                                    type: 'dashed'
                                }
                            }
                        },
                        yAxis: {
                            name: '节水降本（万元）',
                            type: 'value',
                            max: 400,
                            interval: 100,
                            axisTick:{
                                show:false // 不显示坐标轴刻度线
                            },
                            axisLine: {
                                lineStyle: {
                                    color: '#fff', //x轴的颜色
                                    width: 0, //轴线的宽度
                                }
                            },
                            splitLine: {
                                show: true,
                                lineStyle:{
                                    color: ['#1e8f63'],
                                    width: 1,
                                    type: 'dashed'
                                }
                            }
                        },
                        grid: {
                            top: '15%',
                            left: '6%',
                            right: '7%',
                            bottom: '5%',
                            containLabel: true
                        },
                        series: [
                            {
                                data: this.listData,
                                type: 'line',
                                symbolSize: 8,
                                itemStyle : {
                                    normal : {
                                        color:'rgba(21, 231, 171, 1)',
                                        borderWidth: 2, // 描边的线宽
                                        lineStyle:{
                                            color:'rgba(21, 231, 171, 1)'
                                        }
                                    }
                                }
                            },
                        ]
                    })
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .cost-statistic-wrap {
        height: 100%;
        padding-top: 10px;
        padding-left: 6px;
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;
        .cost-statistic-echars {
            height: 100%;
        }
    }
</style>
