import axios from 'axios'

export default {
  install (Vue, options) {
    axios.defaults.baseURL = options.api
    axios.defaults.headers.post['Content-Type'] = 'application/json'
    axios.defaults.headers.post['Accept'] = 'application/json; charset=utf-8, text/plain, */*'
    axios.defaults.headers.put['Content-Type'] = 'application/json'
    axios.defaults.headers.put['Accept'] = 'application/json; charset=utf-8, text/plain, */*'
    axios.defaults.withCredentials = true

    axios.interceptors.request.use(function (request) {
      let user = Vue.store.state.user
      if (user && user.token) {
        request.headers.token = user.token
      }
      let url = request.url
      let tpi = url.indexOf('_t=')
      if (tpi !== -1) {
        url = url.substring(0, tpi - 1)
      }

      let c = url.indexOf('?') === -1 ? '?' : '&'
      request.url = `${url}${c}_t=${parseInt(new Date().getTime() / 1000, 10)}`

      return request
    }, error => Promise.reject(error))

    axios.interceptors.response.use(response => {
      if (response.config.notProcessed) return response // 业务报错不处理直接返回
      if (response.data.status !== 'yes') {
        Vue.prototype.$message.error(response.data.msg || response.data.message || `请求数据异常`)
        return Promise.reject(response)
      }
      return response
    }, error => {
      let data = error.response.data || {}
      if (data.code === 401) {
        Vue.prototype.$message.info('登录过期，系统即将自动刷新登录')
        sessionStorage.clear()
        localStorage.clear()
        setTimeout(() => { location.href = location.origin }, 2000)
      } else {
        if (data.msg || data.message) {
          Vue.prototype.$message.error(data.msg || data.message)
        } else {
          Vue.prototype.$message.error(`网络请求失败${data.code || ':' + data.code}`)
        }
      }
      return Promise.reject(error)
    })

    Vue.http = Vue.prototype.$http = axios
  },
  $http: axios
}
