<template>
    <div class="fact-video-wrap">
<!--        <div class="fact-video-left" />-->
        <div class="fact-video-video">
            <video v-show="type === 'stage1'" muted loop autoplay ref="videoPlayer1" @click="clickEvent(1)" />
            <video v-show="type === 'stage2'" muted loop autoplay ref="videoPlayer2" @click="clickEvent(2)" />
            <video v-show="type === 'stage3'" muted loop autoplay ref="videoPlayer3" @click="clickEvent(3)" />
            <video v-show="type === 'stage4' || type === 'stage5'" muted loop autoplay ref="videoPlayer4" @click="clickEvent(4)" />
        </div>
<!--        <div class="fact-video-right" />-->
    </div>
</template>
<script>
    import HLS from 'hls.js'
    export default {
        name: "factVideo",
        props: {
            type: {
                type: String,
                default: 'stage1'
            }
        },
        data () {
            return {
                hls1: null,
                hls2: null,
                hls3: null,
                hls4: null,
                url1: 'https://open.ys7.com/v3/openlive/J99375793_1_1.m3u8?expire=1700352578&id=515801154043518976&t=abcf8a753aedfa037e6dd07706fda900babb4fffea5e8084f785e0cea58a0c1a&ev=100',
                url2: 'https://open.ys7.com/v3/openlive/J99376399_1_1.m3u8?expire=1700353251&id=515803979137945600&t=aee5d4a68a2ef400c689c53c6e97abdc47b4e55c45e934a93621918903d1ca6d&ev=100',
                url3: 'https://open.ys7.com/v3/openlive/J99376335_1_1.m3u8?expire=1700353127&id=515803457504940032&t=dfed6039405ba6f849131b73bb01fbf40c09afaf774f2a72f4a5002ee3e41c59&ev=100',
                url4: 'https://open.ys7.com/v3/openlive/J99376277_1_1.m3u8?expire=1700352979&id=515802839113535488&t=e7d8cf1306342b27a0137982fb39b9537ddd75d2e642f90307ea480e382213df&ev=100',
            }
        },
        created () {
            this.hls1 = new HLS()
            this.hls2 = new HLS()
            this.hls3 = new HLS()
            this.hls4 = new HLS()
            this.$nextTick(() => {
                this.start(1)
                this.start(2)
                this.start(3)
                this.start(4)
            })
        },
        activated() {
            this.$nextTick(() => {
                this.$refs.videoPlayer1 && this.$refs.videoPlayer1.play()
                this.$refs.videoPlayer2 && this.$refs.videoPlayer2.play()
                this.$refs.videoPlayer3 && this.$refs.videoPlayer3.play()
                this.$refs.videoPlayer4 && this.$refs.videoPlayer4.play()
            })
        },
        methods: {
            clickEvent (num) {
                this.$refs['videoPlayer' + num].webkitRequestFullScreen()
            },
            start(num){
                if (HLS.isSupported()) {
                    this['hls'+ num].attachMedia(this.$refs['videoPlayer' + num]);
                    this['hls'+ num].loadSource(this['url' + num]);
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .fact-video-wrap {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        .fact-video-video {
            flex: 1;
            min-width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            background-color: rgba(0,0,0,.4);
            overflow: hidden;
            > video {
                /*width: 100%;*/
                height: 100%;
            }
        }
        .fact-video-left, .fact-video-right {
            width: 9px;
            height: 17px;
            background-size: cover;
            cursor: pointer;
        }
        .fact-video-left {
            margin-right: 7px;
            background-image: url("../../../assets/production1/prev.png");
        }
        .fact-video-right {
            margin-left: 7px;
            background-image: url("../../../assets/production1/next.png");
        }
    }
</style>
