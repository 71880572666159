<template>
  <div class="login-layout">
    <div class="login-layout-title">东信花卉数字平台</div>
    <div class="login-box">
      <div class="login-box-title">用户登录</div>
      <el-form :model="loginForm">
        <el-form-item prop="username">
          <el-input size="medium" v-model="loginForm.username" @keydown.native.enter="checkLogin" placeholder="用户名" @focus="errorText = ''" :maxlength="40" prefix-icon="el-icon-user-solid"/>
        </el-form-item>
        <el-form-item prop="password">
          <el-input size="medium" v-model="loginForm.password" @keydown.native.enter="checkLogin" placeholder="密码" @focus="errorText = ''" type="password" :maxlength="40" prefix-icon="el-icon-s-goods" />
        </el-form-item>
<!--        <el-form-item prop="password">-->
<!--          <el-input size="medium" v-model="loginForm.code" @keydown.native.enter="checkLogin" placeholder="验证码" @focus="errorText = ''" :maxlength="4" prefix-icon="el-icon-s-claim">-->
<!--            <div slot="suffix" class="login-code">-->
<!--              <img src="../../assets/production/production-box.png" alt="验证码">-->
<!--            </div>-->
<!--          </el-input>-->
<!--        </el-form-item>-->
      </el-form>
      <div v-if="errorText" class="login-warn-tip common-text-overflow-line2" :title="errorText"><i class="el-icon-warning" />{{errorText}}</div>
      <el-button class="login-box-btn" type="primary" @click="checkLogin" :loading="loading">登 录</el-button>
    </div>
  </div>
</template>

<script>
  import md5 from 'js-md5'

  export default {
    name: 'login',
    data () {
      return {
        errorText: '',
        loading: false,
        loginForm: {
          username: '',
          password: '',
          code: ''
        }
      }
    },
    methods: {
      errorHandle (err) {
        this.errorText = err.msg || '登录异常'
      },
      checkLogin () {
        if (this.loginForm.username == '') return this.errorHandle({msg: '请输入用户名'})
        if (this.loginForm.password == '') return this.errorHandle({msg: '请输入密码'})
        // if (this.loginForm.code == '') return this.errorHandle({msg: '请输入验证码'})
        this.login()
      },
      login () {
          // const { data } = await this.$http.post(`/dongxin/flower/getCaptcha`, {
          //   username: this.loginForm.username,
          //   password: md5(this.loginForm.password)
          // })
          // .catch(({response}) => { this.errorHandle(response.data) })
          // .finally(() => { this.loading = false })
          // if (data.code != 200) return this.errorHandle(data)
          if (this.loginForm.username !== 'admin' && this.loginForm.password !== '123') return this.errorHandle({ msg: '用户名或密码错误' })
          this.$store.commit('login', true)
          this.$router.push('/')
      }
    }
  }
</script>

<style lang="scss">
  .login-layout {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #0f200f;
    background-image: url("../../assets/login/login-bg.png");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    .login-layout-title {
      height: 60px;
      font-size: 56px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #01E7FE;
      background: linear-gradient(0deg, #5ADB75 0%, #E2FFE8 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 31px;
    }
      .login-box {
        width: 434px;
        height: 382px;
        box-sizing: border-box;
        padding: 0 55px;
        background-image: url("../../assets/login/login-box.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        .login-code {
          height: 100%;
          width: 160px;
          overflow: hidden;
          > img {
            object-fit: fill;
          }
        }
        .login-box-title {
          margin: 30px 0 27px;
          font-size: 30px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;
          text-align: center;
        }
        .login-box-btn {
          width: 100%;
          height: 40px;
          font-size: 16px;
          margin-top: 20px;
          border-radius: 3px;
        }
        .login-warn-tip {
          font-size: 14px;
          font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
          font-weight: normal;
          color: #FF6300;
          line-height: 20px;
          i {
            margin-right: 2px;
          }
        }
      }
  }
</style>
