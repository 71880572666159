<template>
    <div class="layout-wrap">
        <div class="layout-header">
            <div class="layout-header-menu">
                <div :class="{ 'layout-header-menu-item fix-scale': true, active: active === '/intelligentize' }" @click="routerEvent('/intelligentize')">智能化节水</div>
                <div :class="{ 'layout-header-menu-item fix-scale': true, active: active === '/management' }" @click="routerEvent('/management')">经营总览</div>
                <div :class="{ 'layout-header-menu-item fix-scale': true, active: active === '/production1' }" @click="routerEvent('/production1')">感知物联</div>
            </div>
            <div class="layout-header-title fix-scale" />
            <div class="layout-header-menu right">
                <div :class="{ 'layout-header-menu-item fix-scale': true, active: active === '/production2' }" @click="routerEvent('/production2')">作物信息</div>
                <div :class="{ 'layout-header-menu-item fix-scale': true, active: active === '/production3' }" @click="routerEvent('/production3')">农事信息</div>
                <div :class="{ 'layout-header-menu-item fix-scale': true, active: active === '/marketing' }" @click="routerEvent('/marketing')">品牌化营销</div>
            </div>
        </div>
        <div class="layout-content">
            <keep-alive>
                <router-view />
            </keep-alive>
        </div>
    </div>
</template>

<script>
    export default {
        name: "layout",
        data() {
            return {
                active: '/intelligentize'
            }
        },
        methods: {
            routerEvent (router) {
                this.active = router
                this.$router.replace(router)
            }
        }
    }
</script>
