<template>
    <div class="temperature-wrap">
        <div class="temperature-box">
            <div class="temperature-box-item" @click="$emit('click', { type: 'KQWD' })">
                空气温度
                <div>{{init.toFixed(1)}}<span>℃</span></div>
            </div>
            <div class="temperature-box-item" @click="$emit('click', { type: 'TRWD' })">
                土壤温度
                <div>21.5<span>℃</span></div>
            </div>
            <div class="temperature-box-center">
                <span>监测</span>
            </div>
            <div class="temperature-box-item" style="margin-top: 8px;" @click="$emit('click', { type: 'KQSD' })">
                空气湿度
                <div>76.2<span>%</span></div>
            </div>
            <div class="temperature-box-item" style="margin-top: 8px;" @click="$emit('click', { type: 'TRSD' })">
                土壤湿度
                <div>48<span>%</span></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "temperature",
        data () {
            return {
                timer: null,
                init: 24.1,
                min: 24.1,
                max: 25.8
            }
        },
        mounted() {
            this.timer = setInterval(() => {
                if (this.init >= this.max) {
                    clearInterval(this.timer)
                } else {
                    this.init += 0.1
                }
            }, 4000)
        }
    }
</script>

<style scoped lang="scss">
    .temperature-wrap {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        overflow-y: auto;
        .temperature-box {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            position: relative;
            width: 345px;
            height: 191px;
            background-image: url("../../../assets/production1/four-model.png");
            background-size: 100% 100%;
            .temperature-box-center {
                position: absolute;
                top: 63px;
                left: 143px;
                width: 62px;
                height: 62px;
                box-sizing: border-box;
                padding-top: 36px;
                text-align: center;
                background-image: url("../../../assets/production1/temperature-bg-center.png");
                background-size: cover;
                span {
                    font-size: 9px;
                    font-family: Adobe Heiti Std;
                    font-weight: normal;
                    color: #FFFFFF;
                    line-height: 18px;

                    background: linear-gradient(180deg, #78E99A 0%, #5FCEC8 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            .temperature-box-item {
                width: 144px;
                height: 76px;
                box-sizing: border-box;
                padding: 14px 0 0 25px;
                font-size: 10px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 18px;
                cursor: pointer;
                > div {
                    display: block;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #B4FFEE;
                    line-height: 18px;
                    margin-top: 7px;
                    > span {
                        display: inline-block;
                        font-size: 12px;
                        transform: scale(0.8);
                    }
                }
            }
        }
    }
</style>
