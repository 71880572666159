<template>
    <div class="attention-wrap" @click="$emit('click')">
        <div :class="{ 'attention-box': true, 'detail': detail }">
            <div class="attention-box-item">
                空气温度
                <div>{{stage[type][0]}}<span>℃</span></div>
            </div>
            <div class="attention-box-item">
                光照强度
                <div>{{stage[type][3]}}<span>μmol/s/㎡</span></div>
            </div>
            <div class="attention-box-center">
                <span>{{stageText[type]}}</span>
            </div>
            <div class="attention-box-item">
                相对湿度
                <div>{{stage[type][1]}}<span>%</span></div>
            </div>
            <div class="attention-box-item">
                EC值
                <div>{{stage[type][2]}}<span>ms/cm</span></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "attention",
        props: {
            type: {
                type: String,
                default: 'stage1'
            },
            detail: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                stageText: {
                    stage0: '阶段一',
                    stage1: '阶段一',
                    stage2: '阶段二',
                    stage3: '阶段三',
                    stage4: '阶段四',
                    stage5: '阶段四',
                },
                stage: {
                    stage0: ['26~30', '大于80', '小于0.5', '70'],
                    stage1: ['26~30', '大于80', '小于0.5', '70'],
                    stage2: ['18~28', '65~75', '1.5', '100'],
                    stage3: ['18~28', '65~75', '1.5', '100'],
                    stage4: ['15~25', '大于60', '1.5', '90'],
                    stage5: ['15~25', '大于60', '1.5', '90'],
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .attention-wrap {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        overflow-y: auto;
        .attention-box {
            &.detail {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                position: relative;
                width: 566px;
                height: 271px;
                background-image: url("../../../assets/production1/temperature-bg.png");
                background-size: cover;
                .attention-box-center {
                    position: absolute;
                    top: 90px;
                    left: 242px;
                    width: 87px;
                    height: 88px;
                    box-sizing: border-box;
                    padding-top: 29px;
                    text-align: center;
                    background-image: url("../../../assets/production1/temperature-bg-center.png");
                    background-size: cover;
                    span {
                        font-size: 16px;
                        font-family: Adobe Heiti Std;
                        font-weight: normal;
                        color: #FFFFFF;
                        line-height: 67px;

                        background: linear-gradient(180deg, #78E99A 0%, #5FCEC8 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
                .attention-box-item {
                    width: 275px;
                    height: 133px;
                    box-sizing: border-box;
                    padding: 28px 0 0 40px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 18px;
                    cursor: pointer;
                    > div {
                        display: block;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #B4FFEE;
                        line-height: 18px;
                        margin-top: 7px;
                        > span {
                            display: inline-block;
                            font-size: 12px;
                            transform: scale(0.8);
                        }
                    }
                }
            }
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            position: relative;
            width: 345px;
            height: 165px;
            background-image: url("../../../assets/production1/temperature-bg.png");
            background-size: cover;
            .attention-box-center {
                position: absolute;
                top: 55px;
                left: 147px;
                width: 54px;
                height: 54px;
                box-sizing: border-box;
                padding-top: 6px;
                text-align: center;
                background-image: url("../../../assets/production1/temperature-bg-center.png");
                background-size: cover;
                span {
                    font-size: 12px;
                    font-family: Adobe Heiti Std;
                    font-weight: normal;
                    color: #FFFFFF;
                    line-height: 67px;

                    background: linear-gradient(180deg, #78E99A 0%, #5FCEC8 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            .attention-box-item {
                width: 169px;
                height: 77px;
                box-sizing: border-box;
                padding: 20px 0 0 40px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 14px;
                cursor: pointer;
                > div {
                    display: block;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #B4FFEE;
                    line-height: 16px;
                    margin-top: 7px;
                    > span {
                        display: inline-block;
                        font-size: 12px;
                        transform: scale(0.8);
                    }
                }
            }
        }
    }
</style>
