<template>
    <div class="trend-statistic-wrap">
        <img v-show="type === 'stage1'" src="../../../assets/production2/s1.png" alt="阶段1">
        <img v-show="type === 'stage2'" src="../../../assets/production2/s2.png" alt="阶段2">
        <img v-show="type === 'stage3'" src="../../../assets/production2/s3.png" alt="阶段3">
        <img v-show="type === 'stage4' || type === 'stage5'" src="../../../assets/production2/s4.png" alt="阶段4">
<!--        <div class="trend-statistic-echars" id="trendEchars"/>-->
    </div>
</template>

<script>
    export default {
        name: "trend",
        props: {
            type: {
                type: String,
                default: 'stage1'
            }
        },
        data() {
            return {
                // type: 'stage5',
                xAxisStage: {
                    stage0: ['1周', '6周', '46周', '56周'],
                    stage1: ['1周', '6周', '16周', '46周', '56周'],
                    stage2: ['1周', '6周', '16周','26周', '46周', '56周'],
                    stage3: ['1周', '6周', '16周','26周','36周', '46周', '56周'],
                    stage4: ['1周', '6周', '16周','26周','36周', '46周', '56周'],
                    stage5: ['1周', '5周', '10周', '20周','30周','40周', '50周', '60周', '70周', '80周']
                },
                listDataStage: {
                    // stage0: [0, 1, 5, 6],
                    // stage1: [0, 1, 2, 5, 6],
                    // stage2: [0, 1, 2, 3, 5, 6],
                    // stage3: [0 ,1, 2, 3, 4, 5, 6,3,4,1,1],
                    // stage4: [0 ,1, 2, 3, 4, 5, 6],
                    stage5: [1, 2, 3, 4, 5, 5.5, 6, 6.5, 7, 8]
                },
                xAxisData: ['6周','16周','26周','36周'],
                listData: [1, 2, 3, 4]
            }
        },
        created () {
            // this.getData()
        },
        watch: {
            type (nv) {
                // this.getData()
            }
        },
        methods: {
            createEchars (id, option) {
                return this.$echarts.init(document.getElementById(id)).setOption(option)
            },
            async getData () {
                this.$nextTick(() => {
                    this.createEchars('trendEchars', {
                        tooltip: {
                            trigger: 'axis'
                        },
                        xAxis: {
                            type: 'category',
                            data: this.xAxisStage[this.type],
                            axisLabel: { //  如果这个字段不设置，echarts会根据屏宽及横坐标数据自动给出间隔
                                interval: 0, // 间隔长度，可自定义（如果是时间格式，echarts会自动处理）
                                // rotate: 40 // 横坐标上label的倾斜度
                            },
                            axisTick:{
                                show:false // 不显示坐标轴刻度线
                            },
                            axisLine: {
                                lineStyle: {
                                    color: '#fff', //x轴的颜色
                                    width: 0, //轴线的宽度
                                }
                            },
                            splitLine: {
                                show: true,
                                lineStyle:{
                                    color: ['#1e8f63'],
                                    width: 1,
                                    type: 'dashed'
                                }
                            }
                        },
                        yAxis: {
                            name: '(层数)',
                            type: 'value',
                            axisTick:{
                                show:false // 不显示坐标轴刻度线
                            },
                            axisLine: {
                                lineStyle: {
                                    color: '#fff', //x轴的颜色
                                    width: 0, //轴线的宽度
                                }
                            },
                            splitLine: {
                                show: true,
                                lineStyle:{
                                    color: ['#1e8f63'],
                                    width: 1,
                                    type: 'dashed'
                                }
                            }
                        },
                        grid: {
                            top: '15%',
                            left: '6%',
                            right: '6%',
                            bottom: '5%',
                            containLabel: true
                        },
                        series: [
                            {
                                data: this.listDataStage[this.type],
                                type: 'line',
                                symbolSize: 8,
                                itemStyle : {
                                    normal : {
                                        color:'rgba(21, 231, 171, 1)',
                                        borderWidth: 2, // 描边的线宽
                                        lineStyle:{
                                            color:'rgba(21, 231, 171, 1)'
                                        }
                                    }
                                }
                            }
                        ]
                    })
                })
            }
        }
    }
</script>

<style lang="scss">
    .trend-statistic-wrap {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        > img {
            width: 100%;
        }
        /*overflow-y: auto;*/
        /*overflow-x: hidden;*/
        .trend-statistic-echars {
            height: 100%;
        }
    }
</style>
