<template>
    <div class="warn-wrap">
        <div class="warn-item-box">
            <div class="warn-item">
                <div class="warn-item-icon field" />
                <div class="warn-item-content">
                    <div class="warn-item-content-title">高温预警</div>
                    <div class="warn-item-content-desc">
                        数量：<span>14</span>
                    </div>
                </div>
            </div>
            <div class="warn-item">
                <div class="warn-item-icon stock" />
                <div class="warn-item-content">
                    <div class="warn-item-content-title">低温预警</div>
                    <div class="warn-item-content-desc">
                        数量：<span>9</span>
                    </div>
                </div>
            </div>
            <div class="warn-item">
                <div class="warn-item-icon task" />
                <div class="warn-item-content">
                    <div class="warn-item-content-title">高湿度预警</div>
                    <div class="warn-item-content-desc">
                        数量：<span>7</span>
                    </div>
                </div>
            </div>
            <div class="warn-item">
                <div class="warn-item-icon task" />
                <div class="warn-item-content">
                    <div class="warn-item-content-title">低湿度预警</div>
                    <div class="warn-item-content-desc">
                        数量：<span>12</span>
                    </div>
                </div>
            </div>
            <div class="warn-item">
                <div class="warn-item-icon field" />
                <div class="warn-item-content">
                    <div class="warn-item-content-title">虫情预警</div>
                    <div class="warn-item-content-desc">
                        数量：<span>0</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "warn"
    }
</script>

<style scoped lang="scss">
    .warn-wrap {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        padding: 0 5px 0 15px;
        .warn-item-box {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
        }
        .warn-item {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px 0;
            + .warn-item {
                /*margin-top: 15px;*/
            }
            .warn-item-icon {
                /*width: 95px;*/
                /*height: 60px;*/
                width: 62px;
                height: 40px;
                background-size: cover;
                /*margin-right: 32px;*/
                margin-right: 8px;
                &.field {
                    background-image: url("../../../assets/production1/field.png");
                }
                &.stock {
                    background-image: url("../../../assets/production1/stock.png");
                }
                &.task {
                    background-image: url("../../../assets/production1/task.png");
                }
            }
            .warn-item-content {
                width: 90px;
                .warn-item-content-title {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #15DDA3;
                    line-height: 15px;
                    margin-bottom: 11px;
                }
                .warn-item-content-desc{
                    font-size: 12px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 15px;
                    > span {
                        color: #31CB7F;
                        font-size: 14px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
</style>
