<template>
    <div class="runcode-wrap">
        <div class="runcode-box">
            <div class="runcode-box-text">
                <span>扫描右侧二维码</span>
                <span>即可进行溯源</span>
            </div>
            <div class="runcode-box-code">
                <img src="../../assets/marketing/rwm.png" alt="溯源码">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "runcode",
        props: {
            type: { // ['fan', 'curtain', 'worm']
                type: String,
                default: 'fan'
            }
        },
        data () {
            return {
                textMap: {
                    'fan': '风扇',
                    'curtain': '遮阳帘、水帘',
                    'worm': '虫害'
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .runcode-wrap {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        overflow-y: auto;
        .runcode-box {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 318px;
            height: 183px;
            background-image: url("../../assets/production1/panel-bg.png");
            background-size: cover;
            box-sizing: border-box;
            .runcode-box-text {
                font-size: 13px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #00D87F;
                line-height: 23px;
                margin-right: 15px;
                > span {
                    display: block;
                }
            }

            .runcode-box-code {
                border: 3px solid #00D87F;
                padding: 5px;
                width: 112px;
                height: 112px;
                background-color: #fff;
                > img {
                    height: 100%;
                    width: 100%;
                }
                box-sizing: border-box;
            }
        }
    }
</style>
