<template>
    <div class="video-detail-wrap">
        <template v-if="type === '1'">
            <div class="video-detail-img">
                <img src="../../assets/video-detail/1.jpg" alt="展厅">
            </div>
            <div class="video-detail-text">
                <p>线下批发代理：公司在全国28个主要省市（自治区）设立批发代理商，产品销往全国。</p>
                <p>线下展览零售：公司已建成花卉展示交易大厅25812平方米，具备举办花卉（农业）展览及花卉超市双重功能，重点面向线下零售客户。（备注：目前全国各地传统花卉市场在大批拆除，大型花卉超市将成为当前及未来花卉线下销售的主要模式）。</p>
                <p>线上销售：公司在线上开设淘宝企业店、京东企业店、微店和网络销售社群，面向线上批发、零售客户。（备注：随着电商快速发展，园艺产品销售也逐步向线上扩张）。</p>
            </div>
        </template>

        <template v-if="type === '2'">
            <div class="video-detail-img">
                <img src="../../assets/video-detail/2.jpg" alt="一期">
            </div>
            <div class="video-detail-text">
                自主完成26万平方米单体独栋玻璃温室的设计建造安装工程。温室环境控制及水肥灌溉精度达99%以上，内部配套安装了温室环境自动控制系统、悬吊植物自动种植系统、自动栽苗流水线、自动打药机、潮汐灌溉系统等智能化、机械化生产设备，智能化生产水平全国领先。
            </div>
        </template>

        <template v-if="type === '3'">
            <div class="video-detail-img">
                <img src="../../assets/video-detail/3.jpg" alt="二期">
            </div>
            <div class="video-detail-text">
                30万平方米智能温室。其中，自主设计、施工完成的30万平方米智能连栋玻璃温室配套安装了温室环境自动控制系统、潮汐灌溉系统、分级系统、物流缓冲系统、AGV苗床管理系统、自动化悬吊种植系统等，满足了机械化、规模化、信息化生产控制要求，实现温室内温度、光照、湿度、水、肥一体化，分级及内部物流的自动化控制和机械化操作，利用该温室设施进行农业生产，操控精度、工作效率、产品质量显著提升，是国内规模最大、智能化程度最高的设施温室。
            </div>
        </template>

        <template v-if="type === '4'">
            <div class="video-detail-img">
                <img src="../../assets/video-detail/4.jpg" alt="办公楼">
            </div>
            <div class="video-detail-text">
                天津市东信国际花卉有限公司1991年开始在6亩荒地上搭建温室从事设施农业栽植，期间一边总结设施农业栽植经验，一边研究温室设施建设；根据栽植经验和温室环境调控需求，1996年、2001年两次对生产设施进行改造升级，1996年改造为45亩二代日光节能温室，2001年提升为207亩连栋塑料温室，两次提升全部自主设计、建造完成；2009年开始全面建设3458亩天津滨海国际花卉科技园区，这一次，东信花卉站在国际视野制定企业发展战略，决定再次对生产设施、设备升级，建设包括温室设备加工车间、花卉展示交易中心、现代智能连栋玻璃温室、种苗组培研发中心在内的现代花卉科技园区，打造温室设施建设—自动化设备开发—种苗研发—盆花生产—展览销售产业链。2020年6月公司被天津东方财信投资集团有限公司全资收购，注入新的发展动力，重新扬帆起航。
            </div>
        </template>

        <template v-if="type === '5'">
            <div class="video-detail-img">
                <img src="../../assets/video-detail/5.jpg" alt="高温室">
            </div>
            <div class="video-detail-text">
                国内外之前没有超大型智能温室建设先例，为完成温室建设，公司技术人员多次出国考察调研，学习国外先进智能温室建设经验，引进国外先进机械化、自动化设施设备，并根据三十年种植经验、当地环境条件及自身需求进行创新，自主设计、施工完成6万平方米双层拱膜高温室。配套安装了温室环境自动控制系统、自动化悬吊种植等系统。
            </div>
        </template>

        <template v-if="type === '6'">
            <div class="video-detail-img">
                <img src="../../assets/video-detail/6.jpg" alt="厂房">
            </div>
            <div class="video-detail-text">
                公司拥有温室材料加工车间22625平方米。生产部门建立了1条温室盆花标准化生产线，通过信息化、机械化方式进行盆花的标准化、规模化生产，产品质量高，自动化机械的应用节约了大量人工。全资子公司—天津市东信农业设施工程有限公司建立了1条温室建造工艺，包含材料加工、温室地下管道铺设、主体钢结构骨架搭建、覆盖物安装及内部环境调控系统设计安装全套技术。拥有2项发明专利和190项实用新型专利；主持制定1项天津市地方标准；《超大型智能温室技术集成开发与应用》技术被鉴定为国内领先水平。
            </div>
        </template>

        <template v-if="type === '7'">
            <div class="video-detail-img">
                <img src="../../assets/video-detail/7.jpg" alt="组培实验室">
            </div>
            <div class="video-detail-text">
                <p>
                    为适应科研与生产技术需求，东信花卉与中国科学院植物研究所、中国农业大学、南开大学、天津农学院等科研院校开展了不同形式产学研合作，构建了国家地方联合工程实验室、院士专家工作站、天津市花卉技术工程中心等科研转化推广平台，建设了科研协作示范基地、研究生联合培养基地、大中专就业实习基地等人才培养基地，围绕温室建设、自动化设备开发、新品种筛选、种苗培育、病虫害防控等技术进行合作研究和人才培养，并取得阶段性进展，提高了产品的质量效益、企业的核心竞争力和人才的凝聚力。
                </p>
                <p>东信花卉目前已掌握数百个花卉品种的标准化栽培技术体系，已开展70多个花卉品种的种苗生产技术和盆花标准化栽培技术开发，研发、生产、销售经验丰富，工厂化生产技术成熟，是国内外客商青睐的长期合作伙伴，契合国家生态发展战略。</p>
            </div>
        </template>

    </div>
</template>

<script>
    export default {
        name: "videoDetail",
        props: {
            type: {
                type: String,
                default: '1'
            }
        },
        data () {
            return {
                typeMap: {
                    '0': { img: '', desc: '' },
                    '1': { img: '', desc: '' },
                    '2': { img: '', desc: '' },
                    '3': { img: '', desc: '' },
                    '4': { img: '', desc: '' },
                    '5': { img: '', desc: '' },
                    '6': { img: '', desc: '' },
                    '7': { img: '', desc: '' }
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .video-detail-wrap {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 6px;
        .video-detail-img {
            > img {
                width: 100%;
                object-fit: cover;
            }
        }
        .video-detail-text {
            flex: 1;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #F9FAFA;
            line-height: 20px;
            background-color: rgba(0, 89, 52, .2);
            padding: 9px 5px;
            box-sizing: border-box;
            margin-top: 6.5px;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
</style>
