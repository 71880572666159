<template>
    <div class="flower-wrap">
        <el-carousel :interval="4000" type="card" height="160px">
            <el-carousel-item v-for="(img, i) in imgList" :key="i">
                <img class="img-style" :src="img.src" :alt="img.alt" @click="$emit('click', img)">
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
    export default {
        name: "flower",
        data() {
            return {
                imgList: [
                    { src: require(`../../assets/flower/bld.png`), alt: '宝莲灯', desc: '学名粉苞酸脚杆（Medinilla magnifica Lindl）是野牡丹科，酸脚杆属常绿小灌木，宝莲灯茎较短而粗壮，叶形类似柑橘，互生。植株多分枝，花茎从叶腋处抽出，硕大的花蕾类似倒挂的灯笼，开花时的花瓣类似倒开的荷花，花茎顶端和花瓣处挂满类似珍珠宝石的小球，故名宝莲灯花。花色有浅粉色和粉红色。花期为2月至8月，花开时间可长达百天以上。公司宝莲灯品种包括浅粉色、粉色、深粉色，分步骤逐年把不同品种推向市场；公司掌握周年开花花期调控技术，可调控宝莲灯在指定规格指定时间开花，实现宝莲灯全年供货' },
                    { src: require(`../../assets/flower/hz.png`), alt: '红掌', desc: '学名花烛（Anthurium andraeanum Linden）是天南星科花烛属多年生常绿草本植物。茎节短；叶自基部生出，绿色，革质，全缘，长圆状心形或卵心形。叶柄细长，佛焰苞平出，革质并有蜡质光泽，橙红色或猩红色；肉穗花序黄色，可常年开花不断。公司红掌花卉质量国内最好，生产品种国内最多，并拥有多个品种的国内独家种植权，引领全国红掌市场。'  },
                    { src: require(`../../assets/flower/zy.png`), alt: '竹芋', desc: '竹芋（Maranta arundinacea L.），为竹芋科观叶植物。根茎肉质，纺锤形；茎2歧分枝。叶薄，卵形或卵状披针形，绿色，顶端渐尖，基部圆形，背面无毛或薄被长柔毛；叶枕上面被长柔毛；无柄或具短柄；叶舌圆形。总状花序顶生，长15-20厘米，有花数朵，苞片线状披针形，内卷；花小，白色，小花梗长约1厘米；萼片狭披针形；花冠管基部扩大；裂片长8-10毫米；外轮的2枚退化雄蕊倒卵形，内轮的长仅及外轮的一半；子房无毛或稍被长柔毛。果长圆形。花期：夏秋。竹芋作为色彩丰富、观赏性高的观叶植物，是国内份额最大的观叶盆栽之一，公司生产竹芋品种国内最多，产品多次在全国比赛获金奖。'  },
                ]
            }
        },
        created() {

        },
        methods: {}
    }
</script>

<style lang="scss">
    .flower-wrap {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 12px 14px 10px;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        .el-carousel--horizontal {
            width: 100%;
        }
        .el-carousel__button {
            background: #43EA80;
            opacity: 0.5;
            width: 5px;
            height: 5px;
            border-radius: 50%;
        }
        .img-style {
            object-fit: fill;
            width: 100%;
            height: 100%;
            border: 1px solid #43EA80;
            box-sizing: border-box;
        }
    }

</style>
