<template>
    <div class="exhibition-wrap">
        <template v-if="search">
<!--            <div class="exhibition-wrap-search">-->
<!--                <el-input style="width: 300px;margin-right: 10px;" size="medium" v-model="searchVal" placeholder="请输入关键字" />-->
<!--                <el-button size="medium" type="primary" icon="el-icon-search">搜 索</el-button>-->
<!--            </div>-->
            <div @click="$emit('click', 'exhibition1')">
                <el-table class="exhibition-table" :data="tableData" height="100%" size="mini">
                    <el-table-column prop="name" label="展会/订单" show-overflow-tooltip />
                    <el-table-column prop="address" label="地点" show-overflow-tooltip />
                    <el-table-column prop="date" label="时间" show-overflow-tooltip />
                    <el-table-column prop="phone" label="联系方式" show-overflow-tooltip />
                </el-table>
            </div>
        </template>
<!--        <div v-if="!search" class="exhibition-tab">-->
<!--            <div :class="{ 'exhibition-tab-item': true, 'active': active === 'zh' }" @click="active = 'zh'" >展会</div>-->
<!--            <div :class="{ 'exhibition-tab-item': true, 'active': active === 'dd' }" @click="active = 'dd'">订单</div>-->
<!--        </div>-->
        <div v-else class="exhibition-table-scroll" @click="$emit('click', 'exhibition1')">
            <el-table class="exhibition-table scroll-header" :data="[]" height="100%" size="mini">
                <el-table-column prop="name" label="展会/订单" show-overflow-tooltip />
                <el-table-column prop="address" label="地点" show-overflow-tooltip />
                <el-table-column prop="date" label="时间" show-overflow-tooltip />
                <el-table-column prop="phone" label="联系方式" show-overflow-tooltip />
            </el-table>
            <vue-seamless-scroll :data="tableData" :class-option="{ step: 0.2 }">
                <el-table class="exhibition-table scroll-content" :data="tableData" height="100%" size="mini">
                    <el-table-column prop="name" label="展会/订单" show-overflow-tooltip />
                    <el-table-column prop="address" label="地点" show-overflow-tooltip />
                    <el-table-column prop="date" label="时间" show-overflow-tooltip />
                    <el-table-column prop="phone" label="联系方式" show-overflow-tooltip />
                </el-table>
            </vue-seamless-scroll>
        </div>



<!--        <div v-if="active === 'dd'" class="exhibition-table" @click="$emit('click', 'exhibition2')">-->
<!--            <el-table :data="tableData1" height="100%" size="mini">-->
<!--                <el-table-column prop="name" label="花卉名称" show-overflow-tooltip />-->
<!--                <el-table-column prop="price" label="当前价格" show-overflow-tooltip />-->
<!--                <el-table-column prop="change" label="价格变化" show-overflow-tooltip>-->
<!--                    <template slot-scope="{row}">-->
<!--                        {{row.change}} <span :class="{ 'price-icon': true, 'down': row.down }" />-->
<!--                    </template>-->
<!--                </el-table-column>-->
<!--                <el-table-column prop="address" label="所在地" show-overflow-tooltip />-->
<!--            </el-table>-->
<!--        </div>-->
    </div>
</template>

<script>
    import vueSeamlessScroll from 'vue-seamless-scroll'
    export default {
        name: "exhibition",
        props: {
            type: {
                type: String,
                default: 'exhibition1'
            },
            search: {
                type: Boolean,
                default: false
            }
        },
        components: {
            vueSeamlessScroll
        },
        data() {
            return {
                active: 'zh',
                searchVal: '',
                tableData: [
                    { name: '国际花卉园艺展览会', address: '北京', date: '2022.09.15', phone: '010-88102216' },
                    { name: '蟹爪兰订单', address: '天津', date: '2022.11.30', phone: '16622393005' },
                    { name: '中国昆明国际花卉展', address: '昆明', date: '2022.11.11', phone: '0871-63638989' },
                    { name: '宝莲灯订单', address: '北京', date: '2022.12.14', phone: '18645030831' },
                    { name: '丽格海棠订单', address: '天津', date: '2023.01.05', phone: '18602202507' },
                    { name: '中国国际花卉展览会', address: '珠海', date: '2022.11.15', phone: '020-89857249' },
                    { name: '亚洲园林经管产业博览会', address: '广州', date: '2022.11.16', phone: '400-881-0218' },
                    { name: '红掌订单', address: '哈尔滨', date: '2023.02.01', phone: '18646292309' },
                    { name: '蟹爪兰订单', address: '天津', date: '2022.11.30', phone: '16622393005' },
                    { name: '红掌订单', address: '哈尔滨', date: '2023.02.01', phone: '18646292309' },
                    { name: '宝莲灯订单', address: '北京', date: '2022.12.14', phone: '18645030831' },
                ],
                tableData1: [
                    { name: '宝莲灯', change: '1.33', price: '39元', address: '东丽区', down: true },
                    { name: '宝莲灯', change: '1.33', price: '39元', address: '东丽区'},
                    { name: '宝莲灯', change: '1.33', price: '39元', address: '东丽区'},
                    { name: '宝莲灯', change: '1.33', price: '39元', address: '东丽区'},
                    { name: '宝莲灯', change: '1.33', price: '39元', address: '东丽区', down: true},
                    { name: '宝莲灯', change: '1.33', price: '39元', address: '东丽区'},
                    { name: '宝莲灯', change: '1.33', price: '39元', address: '东丽区'},
                    { name: '宝莲灯', change: '1.33', price: '39元', address: '东丽区'},
                    { name: '宝莲灯', change: '1.33', price: '39元', address: '东丽区'},
                    { name: '宝莲灯', change: '1.33', price: '39元', address: '东丽区'},
                    { name: '宝莲灯', change: '1.33', price: '39元', address: '东丽区'}
                ]
            }
        }
    }
</script>

<style lang="scss">
    .exhibition-wrap {
        /*display: flex;*/
        /*flex-direction: column;*/
        /*height: 100%;*/
        /*overflow-x: hidden;*/
        /*overflow-y: auto;*/
        /*padding: 12px;*/
        /*box-sizing: border-box;*/

        height: 100%;
        /*padding: 0 10px 10px;*/
        box-sizing: border-box;
        overflow: hidden;
        .exhibition-wrap-search {
            padding-bottom: 10px;
        }
        .exhibition-tab {
            display: flex;
            height: 20px;
            margin-bottom: 10px;
            .exhibition-tab-item {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFF;
                height: 100%;
                width: 90px;
                background-image: url("../../assets/marketing/tab.png");
                background-repeat: no-repeat;
                background-size: cover;
                cursor: pointer;
                padding: 5px;
                box-sizing: border-box;
                &.active {
                    background-image: url("../../assets/marketing/tab-active.png");
                }
            }
        }
        .exhibition-table {
            /*flex: 1;*/
            /*min-height: 100px;*/
            &.scroll-header {
                z-index: 1;
                .el-table__body-wrapper {
                    display: none;
                }
            }
            &.scroll-content {
                .el-table__header-wrapper {
                    display: none;
                }
            }
            &.el-table th.el-table__cell > .cell {
                padding-left: 20px;
            }
            &.el-table .cell {
                padding-left: 20px!important;
            }
            .el-table__row{
                &:hover {
                    td:first-child {
                        position: relative;
                        &::before {
                            position: absolute;
                            left: 0;
                            top: 0;
                            content: '';
                            height: 35px;
                            width: 13px;
                            background-image: url("../../assets/marketing/select-icon.png");
                            background-repeat: no-repeat;
                            background-size: cover;
                        }
                    }
                }
            }
        }
    }
</style>
