import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "../views/login/Login";
import Layout from "../views/layout/Layout";
import ManagementOverview from "../views/management/ManagementOverview"; // 经营总览
import Production1 from "../views/production/Production1"; // 数字化生产1
import Production2 from "../views/production/Production2"; // 数字化生产2
import Production3 from "../views/production/Production3"; // 数字化生产3
import WaterConservation from "../views/intelligentize/WaterConservation"; // 智能化节水
import MarketingBrand from "../views/marketing/MarketingBrand"; // 品牌化营销
// import demo from "../views/demo/demo";
// import FullDemo from "../views/demo/FullDemo";

Vue.use(VueRouter)

const routes = [
  { path: '/login', component: Login, meta: { login: true } },
  // { path: '/demo', component: demo, meta: { login: true } },
  // { path: '/full-demo', component: FullDemo, meta: { login: true } },
  {
    path: '/',
    name: 'Home',
    component: Layout,
    redirect: "/intelligentize",
    children: [
      {
        path: 'management',
        component: ManagementOverview,
      },
      {
        path: 'production1',
        component: Production1,
      },
      {
        path: 'production2',
        component: Production2,
      },
      {
        path: 'production3',
        component: Production3,
      },
      {
        path: 'intelligentize',
        component: WaterConservation,
      },
      {
        path: 'marketing',
        component: MarketingBrand,
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

// router.beforeEach((to,from,next)=>{
//   if(!to.meta.login){
//     if(Vue.store.state.login){
//       next()
//     } else next({ path:'/login' })
//   } else next()
// })

export default router
