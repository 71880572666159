<template>
    <div class="parameter-wrap">
        <div class="parameter-content">
            <div class="parameter-content-title">生长周期：{{stage[type][0]}}</div>
            <div class="parameter-content-box">
                <div class="parameter-content-item">阶段性状：{{stage[type][1]}}</div>
                <div class="parameter-content-item">盆径：{{stage[type][3]}}</div>
                <div class="parameter-content-item">可催花数：{{stage[type][4]}}</div>
                <div class="parameter-content-item">催花周期：{{stage[type][5]}}</div>
                <div class="parameter-content-item" style="width: 100%">
                    毕业性状：{{stage[type][2]}}
                </div>
            </div>
        </div>
<!--        <div class="parameter-layout">-->
<!--            <el-tooltip class="item" effect="dark" :content="stage[type][0]" placement="right">-->
<!--                <div class="parameter-item common-text-overflow-line1" >生长周期：{{stage[type][0]}}</div>-->
<!--            </el-tooltip>-->
<!--            <el-tooltip class="item" effect="dark" :content="stage[type][1]" placement="right">-->
<!--                <div class="parameter-item common-text-overflow-line1">阶段性状：{{stage[type][1]}}</div>-->
<!--            </el-tooltip>-->
<!--            <el-tooltip class="item" effect="dark" :content="stage[type][2]" placement="right">-->
<!--                <div class="parameter-item common-text-overflow-line1">毕业性状：{{stage[type][2]}}</div>-->
<!--            </el-tooltip>-->
<!--            <el-tooltip class="item" effect="dark" :content="stage[type][3]" placement="right">-->
<!--                <div class="parameter-item common-text-overflow-line1">盆径：{{stage[type][3]}}</div>-->
<!--            </el-tooltip>-->
<!--            <el-tooltip class="item" effect="dark" :content="stage[type][4]" placement="right">-->
<!--                <div class="parameter-item common-text-overflow-line1">可催花数：{{stage[type][4]}}</div>-->
<!--            </el-tooltip>-->
<!--            <el-tooltip class="item" effect="dark" :content="stage[type][5]" placement="right">-->
<!--                <div class="parameter-item common-text-overflow-line1">催花周期：{{stage[type][5]}}</div>-->
<!--            </el-tooltip>-->
<!--        </div>-->
    </div>
</template>

<script>
    export default {
        name: "parameter",
        props: {
            type: {
                type: String,
                default: 'stage1'
            }
        },
        data () {
            return {
                stage: {
                    stage1: ['6周', '2片叶小苗', '生根后到下一阶段', '7.5', '无', '无'],
                    stage2: ['10周', '2层', '换盆后再长出两片成熟叶片到下一阶段', '2/14/17/19', '2朵', '催花处理（8周）'],
                    stage3: ['10周', '3层', '再高一层到下一阶段', '12/14/17/19', '最少4朵', '催花处理（8周）'],
                    stage4: ['10周', '4层', '再高一层到下一阶段', '12/14/17/19', '最高可达8朵', '催花处理（8周）'],
                    stage5: ['10周', '4层', '再高一层到下一阶段', '12/14/17/19', '最高可达8朵', '催花处理（8周）'],
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .parameter-wrap {
        height: 100%;
        padding: 10px 17px;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;

        .parameter-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .parameter-content-title {
                box-sizing: border-box;
                padding-left: 15px;
                width: 140px;
                height: 44px;
                margin: 6px 0;
                background-image: url("../../../assets/production2/parameter-bg.png");
                background-size: cover;
                font-size: 14px;
                font-family: Adobe Heiti Std;
                font-weight: normal;
                color: #fff;
                line-height: 44px;
            }
            .parameter-content-box {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                padding-bottom: 15px;
            }
            .parameter-content-item {
                line-height: 14px;
                padding: 10px 12px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                width: 48%;
                box-sizing: border-box;
                background: linear-gradient(90deg, rgba(11,186,113, .15) 0%, rgba(75,255,179,0.06) 50%, rgba(12,185,112,0.01) 100%);
                margin-top: 10px;
            }
        }

        .parameter-layout {
            display: flex;
            height: 100%;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            .parameter-item {
                /*display: flex;*/
                /*align-items: center;*/
                /*justify-content: center;*/
                box-sizing: border-box;
                padding-left: 15px;
                width: 140px;
                height: 44px;
                margin: 6px 0;
                background-image: url("../../../assets/production2/parameter-bg.png");
                background-size: cover;
                font-size: 14px;
                font-family: Adobe Heiti Std;
                font-weight: normal;
                color: #00D87F;
                line-height: 44px;
            }
        }
    }
</style>
