<template>
    <div class="ctrl-wrap">
        <div class="ctrl-option">
            <el-switch v-if="type !== 'worm'" v-model="switchVal" active-color="#13ce66" inactive-color="#666" />
            数据采集时间：{{$moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss') }}
        </div>
        <div class="ctrl-video">
            <template v-show="switchVal">
                <img v-if="type === 'worm'" src="../../../assets/flower/bld.png" alt="宝莲灯">
                <video v-else ref="videoPlayer" src="../../../assets/test/test.mp4" muted loop autoplay @click="clickEvent" />
            </template>
            <div v-show="!switchVal" class="common-video-close" />
        </div>
    </div>
</template>

<script>
    import HLS from 'hls.js'
    import videoMixins from "../../../components/videoMixins";
    export default {
        name: "ctrlStatistic",
        mixins: [videoMixins],
        props: {
            type: {
                type: String,
                default: 'fan'
            }
        },
        data () {
            return {
                switchVal: true,
                curtain: 'https://open.ys7.com/v3/openlive/J99375647_1_1.m3u8?expire=1700900885&id=518100921880674304&t=badf0c0c43bb4753d2f28b5c78764a4e6e7a523e1c1040345ad98e3f4e30c660&ev=100',
                fan: 'https://open.ys7.com/v3/openlive/J99376338_1_1.m3u8?expire=1700353167&id=515803626816409600&t=590893bf315a87776775230cdf59361e7be9cc5e8b591d4b922404048df79ba8&ev=100',
            }
        },
        methods: {
            start(){
                if (HLS.isSupported()) {
                    this.hls.attachMedia(this.$refs.videoPlayer);
                    this.hls.loadSource(this[this.type]);
                }
            }
        }
    }
</script>

<style lang="scss">
    .ctrl-wrap {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        .ctrl-option {
            display: flex;
            align-items: center;
            padding-bottom: 8px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            .el-switch__core {
                transform: scale(0.6);
                margin-right: 10px;
            }
        }
        .ctrl-video {
            position: relative;
            flex: 1;
            min-width: 100px;
            display: flex;
            align-items: center;
            height: 100%;
            background-color: rgba(0,0,0,.4);
            box-sizing: border-box;
            border: 1px solid #69D09A;
            overflow: hidden;
            > video {
                width: calc(100% - 1px);
            }
            > img {
                width: 100%;
                height: 500px;
                object-fit: cover;
            }
        }
    }
</style>
