<template>
    <div class="variety-wrap">
        <div class="variety-layout-left">
<!--            <div class="variety-left" @click="$refs.imgDom.prev()" />-->
            <div class="variety-img">
                <el-carousel ref="imgDom" indicator-position="outside" arrow="never" :interval="10000" :autoplay="true" @change="i => { index = i }">
                    <el-carousel-item v-for="(img, i) in imgList" :key="i">
                        <img class="img-style" :src="img.src" :alt="img.alt" @click="$emit('click', img)">
                    </el-carousel-item>
                </el-carousel>
            </div>
<!--            <div class="variety-right" @click="$refs.imgDom.next()" />-->
        </div>
        <div class="variety-layout-right">
            <span>{{imgList[index].alt}}</span>
            {{imgList[index].desc}}
        </div>
    </div>
</template>

<script>
    export default {
        name: "variety",
        data () {
            return {
                index: 0,
                imgList: [
                    { src: require(`../../../assets/flower/qfs.png`), alt: '浅粉色', desc: '直立常绿小灌木（有时为附生植物），高1.5-2.5米（盆栽株高30-40厘米）。茎有四棱或四翅，分枝扁平，节上有疣状突起。叶卵形或卵状长圆形，革质，对生，叶脉内陷，无叶柄。' },
                    { src: require(`../../../assets/flower/fs.png`), alt: '粉色', desc: '穗状花序下垂，似风铃，花蕾抽出后先露粉红色苞片，花序上绽出珍珠状粉色小圆花，长45厘米，花冠直径约0.25厘米，红色；子房下位，4-5室，萼片宿存，雄蕊等长，8-10个，花药顶具嚎，单孔开裂。'  },
                    { src: require(`../../../assets/flower/sfs.png`), alt: '深粉色', desc: '花瓣4片，倒卵形至圆形；花外苞片长达3-10厘米，粉红色，别致可爱。果实球形，浆果，粉红色，顶有宿存萼片。花期超长，可达八九个月，自然花期2-8月，单株花期可持续数月，对其生长环境的调控可实现终年开花。'  },
                ]
            }
        }
    }
</script>

<style lang="scss">
    .variety-wrap {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;

        .variety-layout-left {
            flex: 1;
            min-width: 80px;
            height: 100%;
            display: flex;
            align-items: center;
        }

        .variety-layout-right {
            height: 100%;
            width: 140px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #fff;
            line-height: 20px;
            padding: 0 12px;
            > span {
                display: block;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                margin-bottom: 10px;
            }
        }
        .variety-img {
            flex: 1;
            min-width: 100px;
            height: 100%
        }
        .el-carousel--horizontal {
            display: flex;
            flex-direction: column;
            height: 100%;
            .el-carousel__container {
                min-height: 100px;
                flex: 1;
            }
            .el-carousel__indicators--outside {
                /*height: 10%;*/
            }
        }
        .el-carousel__button {
            background: #43EA80;
            opacity: 0.5;
            width: 5px;
            height: 5px;
            border-radius: 50%;
        }
        .img-style {
            object-fit: cover;
            height: 100%;
            width: 100%;
            border: 1px solid #43EA80;
            box-sizing: border-box;
        }
        .variety-left, .variety-right {
            width: 9px;
            height: 17px;
            background-size: cover;
            cursor: pointer;
        }
        .variety-left {
            margin-right: 7px;
            background-image: url("../../../assets/production1/prev.png");
        }
        .variety-right {
            margin-left: 7px;
            background-image: url("../../../assets/production1/next.png");
        }
    }
</style>
