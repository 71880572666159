<template>
    <div class="farming-wrap">
        <div class="farming-statistic-echars" id="farmingEchars"></div>
    </div>
</template>

<script>
    export default {
        name: "farming",
        data() {
            return {
                xAxisData: ['2021 Q3', '2021 Q4', '2022 Q1', '2022 Q2', '2022 Q3'],
                listData1: [5201955.20, 5058061.26, 5298401.27, 5484774.32, 5619410.86],
                listData2: [193, 234, 240340.01, 186373.05, 134636.54],
            }
        },
        created () {
            this.getData()
        },
        methods: {
            createEchars (id, option) {
                return this.$echarts.init(document.getElementById(id)).setOption(option)
            },
            async getData () {
                this.$nextTick(() => {
                    this.createEchars('farmingEchars', {
                        tooltip: {
                            trigger: 'item'
                        },
                        labelLine: {
                            length: 50,   // 第一段引导线的长度
                            length2: 100,	// 第二段引导线的长度
                            show: true,
                            color: "#00ffff",
                        },
                        legend: {
                            top: '1%',
                            left: 'center',
                            icon: 'circle',
                            itemHeight: 6,
                            itemGap: 8,
                            itemWidth:8,//图例图标的宽度
                            textStyle: {
                                color: '#fff',
                                fontSize: 12,
                                lineHeight: 14,
                            }
                        },
                        series: [
                            {
                                name: '农事统计(2022 全年)',
                                type: 'pie',
                                top: '4%',
                                radius: ['40%', '48%'],
                                data: [
                                                { value: 52, name: '上盆次数', itemStyle: { color: '#32C2F7 '} },
                                                { value: 78, name: '换盆次数', itemStyle: { color: '#FF7234'} },
                                                { value: 156, name: '稀盆次数', itemStyle: { color: '#39DDA0'} },
                                                { value: 208, name: '打理次数', itemStyle: { color: '#FF69B4'} },
                                                { value: 26, name: '催花次数', itemStyle: { color: '#6495ED'} }
                                ],
                                label: {
                                    formatter: '{name|{b}}\n{num|{c}}',
                                    minMargin: 10,
                                    edgeDistance: 10,
                                    lineHeight: 20,
                                    rich: {
                                        num: {
                                            fontSize: 14,
                                            fontWeight: 'bold'
                                        },
                                        name: {
                                            fontSize: 12,
                                            color: '#fff'
                                        }
                                    }
                                },
                                emphasis: {
                                    label: {
                                        show: true
                                    }
                                }
                            }
                        ]
                        // series: [
                        //     {
                        //         name: '农事统计',
                        //         type: 'pie',
                        //         radius: ['40%', '48%'],
                        //
                        //         data: [
                        //             { value: 1048, name: '数据一', itemStyle: { color: '#32C2F7 '} },
                        //             { value: 45454, name: '数据二', itemStyle: { color: '#FF7234'} },
                        //             { value: 3434, name: '数据三', itemStyle: { color: '#39DDA0'} },
                        //             { value: 34341, name: '数据四', itemStyle: { color: '#FFBF34'} }
                        //         ]
                        //     }
                        // ]
                    })
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .farming-wrap {
        padding-top: 10px;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;
        .farming-statistic-echars {
            height: 100%;
        }
    }
</style>
