<template>
    <div class="process-wrap">
        <vue-seamless-scroll :data="processList" :class-option="{ step: 0.2 }" >
            <div class="process-item-layout" v-for="(item, i) in processList" :key="i">
                <div class="process-item">
                    <table>
                        <tr>
                            <td class="common-text-overflow-line1">任务类型：</td>
                            <td class="common-text-overflow-line1 green">{{item.type}}</td>
                            <td colspan="2" class="common-text-overflow-line1">任务地点：</td>
                            <td colspan="2" class="common-text-overflow-line1 green">{{item.address}}</td>
                        </tr>
                        <tr>
                            <td class="common-text-overflow-line1">责任人：</td>
                            <td class="common-text-overflow-line1 green">{{item.user}}</td>
                            <td class="common-text-overflow-line1 ">下发时间：</td>
                            <td class="common-text-overflow-line1 green">{{$moment(new Date().getTime() - Math.ceil(Math.random()*10) * 6 * 60 * 60 * 1000).format('YYYY-MM-DD')}}</td>
                            <td class="common-text-overflow-line1">规定完成时间：</td>
                            <td class="common-text-overflow-line1 green">{{$moment(new Date().getTime() - Math.ceil(Math.random()*10) * 60 * 60 * 1000).format('YYYY-MM-DD')}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </vue-seamless-scroll>
    </div>
</template>

<script>
    import vueSeamlessScroll from 'vue-seamless-scroll'
    export default {
        name: "process",
        components: {
            vueSeamlessScroll
        },
        computed: {
            classOption () {
                return {
                    step: 1000, // 数值越大速度滚动越快
                    limitMoveNum: 1, // 开启无缝滚动的数据量 设置(数值<=页面展示数据条数不滚)(超过页面展示条数滚动)
                    openWatch: true, // 开启数据实时监控刷新dom
                    singleHeight: 40,
                    waitTime: 1000 // 单行停顿时间(singleHeight,waitTime)
                }
            }
        },
        data () {
            return {
                processList: [
                    { type: '组织巡田', address: '05.1C-22bay', user: '刘伟华', date: '2022-11-15', time: '2022-11-25' },
                    { type: '组织杀虫', address: '10.4A-6bay', user: '吕红', date: '2022-11-15', time: '2022-11-25' },
                    { type: '设备检查', address: '09.6D-37bay', user: '刘小梅', date: '2022-11-15', time: '2022-11-25' },
                    { type: '设备检查', address: '09.6D-37bay', user: '刘小梅', date: '2022-11-15', time: '2022-11-25' },
                    { type: '设备检查', address: '09.6D-37bay', user: '刘小梅', date: '2022-11-15', time: '2022-11-25' },
                    { type: '设备检查', address: '09.6D-37bay', user: '刘小梅', date: '2022-11-15', time: '2022-11-25' },
                    { type: '设备检查', address: '09.6D-37bay', user: '刘小梅', date: '2022-11-15', time: '2022-11-25' },
                    { type: '设备检查', address: '09.6D-37bay', user: '刘小梅', date: '2022-11-15', time: '2022-11-25' },
                    { type: '设备检查', address: '09.6D-37bay', user: '刘小梅', date: '2022-11-15', time: '2022-11-25' },
                    { type: '设备检查', address: '09.6D-37bay', user: '刘小梅', date: '2022-11-15', time: '2022-11-25' },
                ]
            }
        }
    }
</script>

<style scoped lang="scss">
    .process-wrap {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-x: hidden;
        /*overflow-y: auto;*/
        overflow-y: hidden;
        padding: 10px 16px;
        box-sizing: border-box;
        .process-item-layout {
            position: relative;
            display: flex;
            align-items: center;
            padding: 15px;
            height: 90px;
            background-color: rgba(0, 104, 22, 0.15);
            color: #2CA2F2;
            box-sizing: border-box;
            /*&::after {*/
            /*    display: inline-flex;*/
            /*    align-items: center;*/
            /*    position: absolute;*/
            /*    content: '查看详情';*/
            /*    background-image: url("../../../assets/production3/process-item.png");*/
            /*    right: 0;*/
            /*    top: 0;*/
            /*    font-size: 12px;*/
            /*    font-family: Microsoft YaHei;*/
            /*    font-weight: 400;*/
            /*    color: #FFFFFF;*/
            /*    height: 18px;*/
            /*    width: 60px;*/
            /*    padding-left: 9px;*/
            /*    box-sizing: border-box;*/
            /*    cursor: pointer;*/
            /*}*/
            + .process-item-layout {
                /*margin-top: 6px;*/
            }
            margin-top: 6px;
        }
        .process-item {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            overflow-x: auto;
            overflow-y: hidden;
            > table {
                font-size: 7px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
            }
            tr {
                line-height: 22px;
            }
            td {
                min-width: 30px;
            }
            .green {
                color: rgba(200, 238, 177, 1);
            }
        }
    }
</style>
