<template>
    <div class="land-wrap">
        <div class="land-video">
            <template v-show="switchVal">
                <video src="/video/5.6.mp4" ref="videoPlayer" muted loop autoplay @click="clickEvent" />
            </template>
            <div v-show="!switchVal" class="common-video-close" />
            <div class="land-option">
                <div class="text-area">
                    <fmarquee val="苗床喷淋浇灌,包括水箱和泵箱,所述水箱的侧壁和泵箱的侧壁相焊接,且水箱的内部设置有储水筒,所述泵箱的顶端安装有调节支架,且调节支架的内侧卡接有喷头,所述调节支架的侧壁安装有软管收集架,且软管收集架与喷头之间..." />
                </div>
                <el-switch v-model="switchVal" active-color="#13ce66" inactive-color="#666" />
            </div>
        </div>
    </div>
</template>

<script>
    import marquee from "../layout/marquee";
    // import videoMixins from "../../components/videoMixins";
    export default {
        name: "land",
        components: {
            Fmarquee: marquee
        },
        // mixins: [videoMixins],
        data () {
            return {
                switchVal: true,
                url: 'https://open.ys7.com/v3/openlive/J99376277_1_1.m3u8?expire=1700352979&id=515802839113535488&t=e7d8cf1306342b27a0137982fb39b9537ddd75d2e642f90307ea480e382213df&ev=100'
            }
        },
        methods: {
            clickEvent () {
                this.$refs.videoPlayer.webkitRequestFullScreen()
            }
        }
    }
</script>

<style lang="scss">
    .land-wrap {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        .land-option {
            position: absolute;
            bottom: 0;
            color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
            padding: 3px;
            box-sizing: border-box;
            background-color: rgba(0, 0, 0, .4);
            width: 100%;
            .el-switch__core {
                transform: scale(0.6);
            }
            .text-area {
                line-height: 14px;
                min-width: 50px;
                flex: 1;
            }
        }
        .land-video {
            position: relative;
            flex: 1;
            min-width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            background-color: rgba(0,0,0,.4);
            box-sizing: border-box;
            border: 1px solid #69D09A;
            overflow: hidden;
            > video {
                height: 100%;
                /*width: calc(100% - 1px);*/
            }
        }
    }
</style>
