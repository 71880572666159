<template>
    <div class="price-wrap">
        <template v-if="search">
<!--            <div class="price-wrap-search">-->
<!--                <el-input style="width: 300px;margin-right: 10px;" size="medium" v-model="searchVal" placeholder="请输入花卉名称" />-->
<!--                <el-button size="medium" type="primary" icon="el-icon-search">搜 索</el-button>-->
<!--            </div>-->
            <el-table class="price-wrap-table" :data="tableData" height="100%" size="mini" @click.native="$emit('click', 'price')">
                <el-table-column prop="name" label="花卉名称" show-overflow-tooltip />
                <el-table-column prop="price" label="当前价格" show-overflow-tooltip />
                <el-table-column prop="change" label="价格变化" show-overflow-tooltip>
                    <template slot-scope="{row}">
                        <span class="change-style">{{row.change}}</span><span :class="{ 'price-icon': true, 'down': row.down }" />
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="所在地" show-overflow-tooltip />
            </el-table>
        </template>

        <div v-else class="price-wrap-scroll" @click="$emit('click', 'price')">
            <el-table class="price-wrap-table scroll-header" :data="[]" height="100%" size="mini">
                <el-table-column prop="name" label="花卉名称" show-overflow-tooltip />
                <el-table-column prop="price" label="当前价格" show-overflow-tooltip />
                <el-table-column prop="change" label="价格变化" show-overflow-tooltip />
                <el-table-column prop="address" label="所在地" show-overflow-tooltip />
            </el-table>
            <vue-seamless-scroll :data="tableData" :class-option="{ step: 0.2 }">
                <el-table class="price-wrap-table scroll-content" :data="tableData" height="100%" size="mini">
                    <el-table-column prop="name" label="花卉名称" show-overflow-tooltip />
                    <el-table-column prop="price" label="当前价格" show-overflow-tooltip />
                    <el-table-column prop="change" label="价格变化" show-overflow-tooltip>
                        <template slot-scope="{row}">
                            <span class="change-style">{{row.change}}</span><span :class="{ 'price-icon': true, 'down': row.down }" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="所在地" show-overflow-tooltip />
                </el-table>
            </vue-seamless-scroll>
        </div>
    </div>
</template>

<script>
    import vueSeamlessScroll from 'vue-seamless-scroll'
    export default {
        name: "price",
        props: {
            search: {
                type: Boolean,
                default: false
            }
        },
        components: {
            vueSeamlessScroll
        },
        data() {
            return {
                searchVal: '',
                tableData: [
                    { name: '洋桔梗', change: '0.1', price: '5.6元', address: '云南昆明', down: true },
                    { name: '睡莲', change: '0.2', price: '1元', address: '云南昆明'},
                    { name: '一串红', change: '0.03', price: '0.28元', address: '山东菏泽'},
                    { name: '蓝色妖姬', change: '0.1', price: '1.5元', address: '云南昆明'},
                    { name: '高原红玫瑰', change: '13.6', price: '150元', address: '云南昆明', down: true},
                    { name: '进口芍药', change: '1.33', price: '39元', address: '山东菏泽'},
                    { name: '非洲菊', change: '1.04', price: '2元', address: '云南昆明'},
                    { name: '苹果叶', change: '0.08', price: '3元', address: '云南昆明'},
                    { name: '百合', change: '0.16', price: '6.8元', address: '云南昆明'},
                    { name: '绣球', change: '0.25', price: '5元', address: '云南昆明'},
                    { name: '月季', change: '0.87', price: '1.4元', address: '河南南阳'},
                    { name: '宝莲灯', change: '0.1', price: '13元', address: '山东菏泽'}
                ]
            }
        }
    }
</script>

<style lang="scss">
    .price-wrap {
        height: 100%;
        /*padding: 0 10px 10px;*/
        box-sizing: border-box;
        overflow: hidden;
        .price-wrap-search {
            padding-bottom: 10px;
        }
        .price-icon {
            display: inline-block;
            width: 7px;
            height: 10px;
            background-image: url("../../assets/marketing/icon-up.png");
            background-size: cover;
            margin-left: 5px;
            &.down {
                background-image: url("../../assets/marketing/icon-down.png");
            }
        }
    }
    .price-wrap-table {
        &.scroll-header {
            z-index: 1;
            .el-table__body-wrapper {
                display: none;
            }
        }
        &.scroll-content {
            .el-table__header-wrapper {
                display: none;
            }
        }
        .change-style {
            display: inline-block;
            width: 30px;
        }
        &.el-table th.el-table__cell > .cell {
            padding-left: 20px;
        }
        &.el-table .cell {
            padding-left: 20px;
        }
        .el-table__row{
            &:hover {
                td:first-child {
                    position: relative;
                    &::before {
                        position: absolute;
                        left: 0;
                        top: 0;
                        content: '';
                        height: 35px;
                        width: 13px;
                        background-image: url("../../assets/marketing/select-icon.png");
                        background-repeat: no-repeat;
                        background-size: cover;
                    }
                }
            }
        }
    }
</style>
