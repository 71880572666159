<template>
    <div class="monitoring-wrap">
<!--        <div class="monitoring-left" />-->
        <div class="monitoring-video">
            <video v-show="type == '1'" muted loop autoplay ref="videoPlayer1" @click="clickEvent(1)" />
            <video v-show="type == '2'" muted loop autoplay ref="videoPlayer2" @click="clickEvent(2)" />
        </div>
<!--        <div class="monitoring-right" />-->
    </div>
</template>

<script>
    import HLS from 'hls.js'
    export default {
        name: "monitoring",
        data () {
            return {
                type: 1,
                hls1: null,
                hls2: null,
                hls3: null,
                hls4: null,
                url1: 'https://open.ys7.com/v3/openlive/J99376322_1_1.m3u8?expire=1700353089&id=515803300159823872&t=35f51e75e413fb6eae5d01aaf1f65070e351c54fcf497075b399595563f36b65&ev=100',
                url2: 'https://open.ys7.com/v3/openlive/J99376339_1_1.m3u8?expire=1700353199&id=515803760283353088&t=a20e836912c4e7e5b9e26bafd1c6b33b6891455af41692be087109c71da5ab2e&ev=100',
                url3: 'https://open.ys7.com/v3/openlive/J99376335_1_1.m3u8?expire=1700353127&id=515803457504940032&t=dfed6039405ba6f849131b73bb01fbf40c09afaf774f2a72f4a5002ee3e41c59&ev=100',
                url4: 'https://open.ys7.com/v3/openlive/J99376277_1_2.m3u8?expire=1700352979&id=515802838828326912&t=01fa85fd34332241137227c79f1d232f69a840053913db37ecbe23d988616b20&ev=100',
            }
        },
        created () {
            this.hls1 = new HLS()
            this.hls2 = new HLS()
            // this.hls3 = new HLS()
            // this.hls4 = new HLS()
            this.$nextTick(() => {
                this.start(1)
                this.start(2)
                // this.start(3)
                // this.start(4)
                setInterval(() => {
                    if (this.type == 1) this.type = 2
                    else if (this.type == 2) this.type = 1
                }, 30000)
            })
        },
        activated() {
            this.$nextTick(() => {
                this.$refs.videoPlayer1 && this.$refs.videoPlayer1.play()
                this.$refs.videoPlayer2 && this.$refs.videoPlayer2.play()
                this.$refs.videoPlayer3 && this.$refs.videoPlayer3.play()
                this.$refs.videoPlayer4 && this.$refs.videoPlayer4.play()
            })
        },
        methods: {
            clickEvent (num) {
                this.$refs['videoPlayer' + num].webkitRequestFullScreen()
            },
            start(num){
                if (HLS.isSupported()) {
                    this['hls'+ num].attachMedia(this.$refs['videoPlayer' + num]);
                    this['hls'+ num].loadSource(this['url' + num]);
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .monitoring-wrap {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        .monitoring-video {
            flex: 1;
            min-width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            background-color: rgba(0,0,0,.4);
            overflow: hidden;
            > video {
                /*width: 100%;*/
                height: 100%;
            }
        }
        .monitoring-left, .monitoring-right {
            width: 9px;
            height: 17px;
            background-size: cover;
            cursor: pointer;
        }
        .monitoring-left {
            margin-right: 7px;
            background-image: url("../../../assets/production1/prev.png");
        }
        .monitoring-right {
            margin-left: 7px;
            background-image: url("../../../assets/production1/next.png");
        }
    }
</style>
