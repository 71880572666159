<template>
    <div class="temperature-statistic-wrap">
        <div class="temperature-statistic-echars" id="temperatureEchars"/>
    </div>
</template>

<script>
    import moment from 'moment'
    export default {
        name: "temperatureStatistic",
        props: {
            type: {
                type: String,
                default: 'KQWD'
            }
        },
        data() {
            return {
                xAxisData: [
                    moment(new Date().getTime() - 6 * 24 * 60 * 60 * 1000).format('MM-DD'),
                    moment(new Date().getTime() - 5 * 24 * 60 * 60 * 1000).format('MM-DD'),
                    moment(new Date().getTime() - 4 * 24 * 60 * 60 * 1000).format('MM-DD'),
                    moment(new Date().getTime() - 3 * 24 * 60 * 60 * 1000).format('MM-DD'),
                    moment(new Date().getTime() - 2 * 24 * 60 * 60 * 1000).format('MM-DD'),
                    moment(new Date().getTime() - 1 * 24 * 60 * 60 * 1000).format('MM-DD'),
                    moment(new Date().getTime()).format('MM-DD')
                ],
                listDataForKQWD: [31.1, 32.5, 14.3, 12.6, 21.9, 10.2, 12.8],
                listDataForKQSD: [11.11, 21.13, 13.12, 11.14, 15.16, 32.1, 14.5],
                listDataForTRSD: [22, 34, 13, 45, 33.16, 45, 21],
                listDataForTRWD: [31.1, 32.5, 24.3, 23.6, 21.9, 10.2, 12.8],
                listData: [1.11, 1.13, 1.12, 1.14, 1.16],
                unitMap: {
                    KQWD: '℃',
                    KQSD: '%',
                    TRSD: '%',
                    TRWD: '℃'
                }
            }
        },
        created () {
            this.getData()
        },
        methods: {
            createEchars (id, option) {
                return this.$echarts.init(document.getElementById(id)).setOption(option)
            },
            async getData () {
                this.$nextTick(() => {
                    this.createEchars('temperatureEchars', {
                        tooltip: {
                            trigger: 'axis'
                        },
                        xAxis: {
                            type: 'category',
                            data: this.xAxisData,
                            axisLabel: { //  如果这个字段不设置，echarts会根据屏宽及横坐标数据自动给出间隔
                                interval: 0, // 间隔长度，可自定义（如果是时间格式，echarts会自动处理）
                                // rotate: 40 // 横坐标上label的倾斜度
                            },
                            axisTick:{
                                show:false // 不显示坐标轴刻度线
                            },
                            axisLine: {
                                lineStyle: {
                                    color: '#fff', //x轴的颜色
                                    width: 0, //轴线的宽度
                                }
                            },
                            splitLine: {
                                show: true,
                                lineStyle:{
                                    color: ['#1e8f63'],
                                    width: 1,
                                    type: 'dashed'
                                }
                            }
                        },
                        yAxis: {
                            name: this.unitMap[this.type],
                            type: 'value',
                            axisTick:{
                                show:false // 不显示坐标轴刻度线
                            },
                            axisLine: {
                                lineStyle: {
                                    color: '#fff', //x轴的颜色
                                    width: 0, //轴线的宽度
                                }
                            },
                            splitLine: {
                                show: true,
                                lineStyle:{
                                    color: ['#1e8f63'],
                                    width: 1,
                                    type: 'dashed'
                                }
                            }
                        },
                        grid: {
                            top: '8%',
                            left: '6%',
                            right: '5%',
                            bottom: '5%',
                            containLabel: true
                        },
                        series: [
                            {
                                data: this[`listDataFor${this.type}`],
                                type: 'line',
                                symbolSize: 8,
                                itemStyle : {
                                    normal : {
                                        color:'rgba(21, 231, 171, 1)',
                                        borderWidth: 2, // 描边的线宽
                                        lineStyle:{
                                            color:'rgba(21, 231, 171, 1)'
                                        }
                                    }
                                }
                            },
                        ]
                    })
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .temperature-statistic-wrap {
        height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
        .temperature-statistic-echars {
            height: 100%;
        }
    }
</style>
