<template>
    <div class="fields-wrap">
        <div class="fields-item">
            <div class="fields-item-bg" style="color: #53FEC0;">
                <div id="ws">320000</div>
            </div>
            <div class="fields-item-desc">智能温室灌溉单元</div>
            <div class="fields-item-unit">(㎡)</div>
        </div>
        <div class="fields-item">
            <div class="fields-item-bg v1" style="color: #FFA63D;">
                <div id="gdwssl">48000</div>
            </div>
            <div class="fields-item-desc">高大温室灌溉单元</div>
            <div class="fields-item-unit">(㎡)</div>
        </div>
        <div class="fields-item">
            <div class="fields-item-bg v2" style="color: #00CCFF;">
                <div id="zzwsl">22000</div>
            </div>
            <div class="fields-item-desc" style="width: 30px;">苗床<span>数量</span></div>
            <div class="fields-item-unit">(个)</div>
        </div>
    </div>
</template>

<script>
    import DigitRoll from '../../../extend/DigitRoll/DigitRoll'
    export default {
        name: "fields",
        activated() {
            this.$nextTick(() => {
                new DigitRoll({ container: '#ws', width: 6 }).roll(320000)
                new DigitRoll({ container: '#gdwssl', width: 5 }).roll(48000)
                new DigitRoll({ container: '#zzwsl', width: 5 }).roll(22000)
            })
        }
    }
</script>

<style scoped lang="scss">
    .fields-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 10px 30px;
        box-sizing: border-box;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        .fields-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 23%;
            height: 120px;
            .fields-item-bg {
                padding-bottom: 55px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 72.5px;
                height: 38.5px;
                background-image: url("../../../assets/production3/1.png");
                background-size: 100% auto;
                background-repeat: no-repeat;
                background-position: bottom;
                margin-bottom: 17px;
                &.v1 {
                    background-image: url("../../../assets/production3/2.png");
                }
                &.v2 {
                    background-image: url("../../../assets/production3/3.png");
                }
                > div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 19px;
                    font-size: 22px;
                    font-family: Arial;
                    font-weight: bold;
                    line-height: 4px;
                }
                > span {
                    display: inline-flex;
                    justify-content: center;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    transform: scale(0.7);
                    width: 80px;
                }
            }
            .fields-item-desc {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFF;
                line-height: 16px;
                text-align: center;
                width: 60px;
            }
            .fields-item-unit {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                line-height: 12px;
            }
        }
    }
</style>
