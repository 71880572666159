<template>
    <div class="investment-wrap">
        <div class="investment-layout">
            <div class="investment-box">
                <div class="investment-echars" id="investmentEchars" />
                <transition name="fade">
                    <div v-if="showBox" class="investment-box-text">
                        实际用水 占比{{(((useData || 0)/(listData[0].value || 0)) * 100).toFixed(2)}}%<span class="">{{useData}}万吨</span>
                    </div>
                </transition>
                <transition name="fade">
                    <div v-if="showBox" class="investment-box-text-other">
                        节水量 占比{{(((otherData || 0)/(listData[0].value || 0)) * 100).toFixed(2)}}%<span class="">{{otherData}}万吨</span>
                    </div>
                </transition>
            </div>
            <div class="investment-tip">
                <div class="investment-tip-item">
                    <span />投入量
                </div>
                <div class="investment-tip-item count">
                    <span />节水量
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "investment",
        data() {
            return {
                timer: null,
                showBox: false,
                listData: [{ name: '理论需水量', value: 127.2 }],
                useData: 36.7,
                otherData: 90.5,
                count: .02
            }
        },
        // created () {
        //     this.getData()
        // },
        activated () {
            this.$nextTick(() => {
                this.timer = setInterval(() => {
                    this.count += .1
                    this.getData()
                    if (this.count > 1) {
                        clearInterval(this.timer)
                        this.count = this.useData/(this.listData[0].value)
                        this.showBox = true
                    }
                }, 100)
            })
        },
        deactivated () {
            clearInterval(this.timer)
            this.showBox = false
        },
        methods: {
            createEchars (id, option) {
                return this.$echarts.init(document.getElementById(id)).setOption(option)
            },
            async getData () {
                this.$nextTick(() => {
                    this.createEchars('investmentEchars', {
                        "series": [
                            {
                                "name": "个人指标",
                                "type": "gauge",
                                "radius": "90%",
                                "startAngle": "90",
                                "endAngle": "-269.9",
                                // 图表的刻度分隔段数
                                "splitNumber": 40,
                                // 图表的轴线相关
                                "axisLine": {
                                    "show": true,
                                    "lineStyle": {
                                        "color": [ [this.count, 'rgba(255, 186, 105, .7)'], [1, 'rgba(0, 247, 143, 1)'] ],
                                        "width": 15
                                    }
                                },
                                // 图表的刻度及样式
                                "axisTick": {
                                    "lineStyle": {
                                        "color": "#0F1318",
                                        "width": 5
                                    },
                                    "length": 15,
                                    "splitNumber": 1
                                },
                                // 图表的刻度标签(20、40、60等等)
                                "axisLabel": {
                                    "show": false
                                },
                                // 图表的分割线
                                "splitLine": {
                                    "show": false
                                },
                                // 图表的指针
                                "pointer": {
                                    "show": false
                                },
                                // 图表的数据详情
                                "detail": {
                                    "formatter": function (value) {//圆心处 描述文字 格式
                                        return value + ' 万吨'
                                    },
                                    "offsetCenter": [0, "20%"],
                                    "fontWeight": 900,
                                    "color": "#00A05C",
                                    "fontSize": 16
                                },
                                // 图表的标题
                                "title": {
                                    "offsetCenter": [0, "-20%"],
                                    "color": "#00A05C",
                                    "fontSize": 18
                                },
                                "data": this.listData
                            }
                        ]
                    })
                })
            }
        }
    }
</script>

<style lang="scss">
    .fade-enter-active, .fade-leave-active {
        transition: opacity 1s;
    }
    .fade-enter,
    .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .investment-wrap {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        overflow-y: auto;
        .investment-layout {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
        }
        .investment-box {
            position: relative;
            width: 200px;
            height: 200px;
            background-image: url("../../assets/intelligentize/investment-bg.png");
            background-size: contain;
            background-repeat: no-repeat;
            &:before {
                position: absolute;
                top: -4px;
                left: 50%;
                content: '';
                width: 8px;
                height: 8px;
                background: #47FFC9;
                border-radius: 50%;
                transform: translateX(-50%);
            }
            .investment-box-text-other {
                position: absolute;
                top: 79px;
                right: -120px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #00F78F;
                line-height: 23px;
                > span {
                    display: block;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #00F08A;
                    line-height: 23px;
                    text-shadow: 2px 2px 5px #00F08A;
                }
                &::before {
                    position: absolute;
                    top: 32px;
                    left: -36px;
                    content: "";
                    height: 1px;
                    width: 38px;
                    background: #00F08A;
                    transform: rotate(-40deg);
                }
            }
            .investment-box-text {
                position: absolute;
                top: -4px;
                right: -114px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: rgba(255, 186, 105, .7);
                line-height: 23px;
                > span {
                    display: block;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: rgba(255, 186, 105, .7);
                    line-height: 23px;
                    text-shadow: 2px 2px 5px rgba(255, 186, 105, .7);
                }
                &::before {
                    position: absolute;
                    top: 12px;
                    left: -19px;
                    content: "";
                    height: 1px;
                    width: 10px;
                    background: rgba(255, 186, 105, .7);
                }
                &::after {
                    position: absolute;
                    top: -3px;
                    left: -41px;
                    content: "";
                    height: 44px;
                    width: 1px;
                    background: rgba(255, 186, 105, .7);
                    display: inline-block;
                    transform: rotate(73deg);
                }
            }
        }
       .investment-echars {
           width: 200px;
           height: 200px;
       }
       .investment-tip {
           height: 200px;
           width: 80px;
           padding-top: 145px;
           box-sizing: border-box;
           margin-left: 16.5px;
           .investment-tip-item {
               display: flex;
               width: 80px;
               align-items: center;
               font-size: 12px;
               font-family: Microsoft YaHei;
               font-weight: 300;
               color: rgba(255, 186, 105, .7);
               line-height: 23px;
               &.count {
                   color: #00E684;
                   > span {
                       background: #00E585
                   }
               }
               > span {
                   display: inline-block;
                   width: 8px;
                   height: 6px;
                   background: rgba(255, 186, 105, .7);
                   margin-right: 5px;
               }
           }
       }
    }
</style>
