<template>
    <div :class="['btn-group-wrap', mode]">
        <div v-for="(item, i) in group" :class="{ 'btn-group-item fix-scale': true, 'active': item.type === active }" :key="i" @click="clickEvent(item)">{{item.name}}</div>
    </div>
</template>

<script>
    export default {
        name: "btnGroup",
        props: {
            mode: {
               type: String,
               default: 'vertical' // horizontal
            },
            defaultActive: {
               type: Boolean,
               default: true
            },
            defaultActiveText: {
                type: String,
                default: ''
            },
            group: {
                type: Array,
                default: () => [
                    // { name: '概览', type: '' },
                    { name: '阶段1', type: 'stage1' },
                    { name: '阶段2', type: 'stage2' },
                    { name: '阶段3', type: 'stage3' },
                    { name: '阶段4', type: 'stage4' },
                    { name: '催花阶段', type: 'stage5' },
                ]
            }
        },
        data () {
            return {
                active: ''
            }
        },
        created() {
            if (this.defaultActive) this.active = this.group[0]?.type
            if (this.defaultActiveText) this.active = this.defaultActiveText
        },
        methods: {
            clickEvent (item) {
                if (this.defaultActive) {
                    if (this.active === item.type) return
                    this.active = item.type
                    this.$emit('click', item)
                } else {
                    if (this.active === item.type) {
                        this.active = ""
                        this.$emit('click', "")
                        return
                    }
                    this.active = item.type
                    this.$emit('click', item)
                }
            }
        }
    }
</script>

<style scoped lang="scss">
        .btn-group-wrap {
            position: absolute;
            z-index: 10;
            &.vertical {
                 top: 50%;
                 right: 20px;
                 transform: translateY(-50%);
             }
            &.horizontal {
                display: flex;
                left: 50%;
                bottom: 20px;
                transform: translateX(-50%);
                .btn-group-item {
                    + .btn-group-item {
                        margin-top: 0;
                        margin-left: 14px;
                    }
                }
            }
            .btn-group-item {
                text-align: center;
                width: 91px;
                height: 31px;
                background-image: url("../../../assets/production2/btn-group-item.png");
                background-size: cover;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ADEAB1;
                line-height: 30px;
                cursor: pointer;

                &.active {
                    background-image: url("../../../assets/production2/btn-group-item-active.png");
                    color: #fff;
                }
                + .btn-group-item {
                    margin-top: 14px;
                }
            }
        }
</style>
