<template>
    <div class="layout-content-wrap">
        <div class="layout-content-left">
            <div class="layout-content-box" style="flex: 2;">
                <span class="layout-content-box-title fix-scale" >各省市销量</span>
                <div class="layout-content-box-block">
                    <sell class="fix-scale" />
                </div>
            </div>
<!--            <div class="layout-content-box">-->
<!--                <span class="layout-content-box-title fix-scale">各经销商成交额总览</span>-->
<!--                <div class="layout-content-box-block">-->
<!--                    <turnover class="fix-scale" />-->
<!--                </div>-->
<!--            </div>-->
            <div class="layout-content-box" style="height: 400px;">
                <span class="layout-content-box-title fix-scale">溯源码展示</span>
                <div class="layout-content-box-block">
                    <runcode class="fix-scale" />
                </div>
            </div>
        </div>
        <div class="layout-content-center map-img">
            <img src="../../assets/marketing/map.png" alt="地图" />
<!--            <mapStatistic class="fix-scale" />-->
        </div>
        <div class="layout-content-right">
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">花卉价格走势</span>
                <div class="layout-content-box-block">
                    <price class="fix-scale" @click="clickEvent" />
                </div>
            </div>
            <div class="layout-content-box">
                <span class="layout-content-box-title fix-scale">展会与需求信息</span>
                <div class="layout-content-box-block">
                    <exhibition class="fix-scale" @click="clickEvent" />
                </div>
            </div>
        </div>
        <el-dialog class="fix-scale" :title="dialogTitleMap[dialogType]" :visible.sync="showDialog" width="800px" :close-on-click-modal="true" top="6%">
            <price v-if="dialogType === 'price'" search />
            <exhibition v-if="dialogType === 'exhibition1'" type="exhibition1" search />
            <exhibition v-else-if="dialogType === 'exhibition2'" type="exhibition2" search />
        </el-dialog>
    </div>
</template>

<script>
    import sell from "./sell";
    import price from "./price";
    import runcode from "./runcode";
    import exhibition from "./exhibition";
    // import turnover from "./turnover";
    // import mapStatistic from "./mapStatistic";
    export default {
        name: "MarketingBrand",
        components: {
            sell,
            price,
            runcode,
            exhibition,
            // turnover,
            // mapStatistic
        },
        data() {
            return {
                dialogType: '',
                dialogTitleMap: {
                    price: '花卉价格走势',
                    exhibition1: '展会信息',
                    exhibition2: '订单信息'
                },
                showDialog: false,
                selectObj: {}
            }
        },
        methods: {
            clickEvent (type) {
                this.dialogType = type
                this.showDialog = true
            }
        },
        watch: {
            showDialog (nv) {
                if (!nv) {
                    this.dialogType = ''
                    this.selectObj = {}
                }
            }
        }
    }
</script>

<style lang="scss">
    .map-img {
        padding: 0 25px;
        object-fit: contain;
    }
</style>

