<template>
    <div class="revenue-statistic-wrap">
        <div class="revenue-statistic-echars" id="revenueEchars"/>
    </div>
</template>

<script>
    import Vue from 'vue'
    export default {
        name: "revenueStatistic",
        data() {
            return {
                xAxisData: ['2021 Q1', '2021 Q2', '2021 Q3', '2021 Q4', '2021 全年营收', '2022 Q1', '2022 Q2', '2022 Q3', '2022 Q4', '本年至今'],
                listData1: [0.39,  0.31, 0.22, 0.39, 0, 0.45, 0.35, 0.25, 0.45, 0],
                listData2: [0, 0, 0, 0, 1.31, 0, 0, 0, 0, 1.5]
            }
        },
        created () {
            this.getData()
        },
        methods: {
            createEchars (id, option) {
                return this.$echarts.init(document.getElementById(id)).setOption(option)
            },
            async getData () {
                this.$nextTick(() => {
                    this.createEchars('revenueEchars', {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        grid: {
                            top: '14%',
                            left: '6%',
                            right: '7%',
                            bottom: '3%',
                            containLabel: true
                        },
                        yAxis: {
                            name: '营收数据（亿元）',
                            type: 'value',
                            boundaryGap: [0, 0.01],
                            max: 3,
                            interval: 0.5,
                            axisTick:{
                                show:false // 不显示坐标轴刻度线
                            },
                            axisLine: {
                                lineStyle: {
                                    color: '#fff', //x轴的颜色
                                    width: 0, //轴线的宽度
                                }
                            },
                            splitLine: {
                                show: true,
                                lineStyle:{
                                    color: ['#1e8f63'],
                                    width: 1,
                                    type: 'solid'
                                }
                            }
                        },
                        xAxis: {
                            name: '',
                            type: 'category',
                            data: this.xAxisData,
                            axisLabel: { //  如果这个字段不设置，echarts会根据屏宽及横坐标数据自动给出间隔
                                interval: 0, // 间隔长度，可自定义（如果是时间格式，echarts会自动处理）
                                rotate: 40 // 横坐标上label的倾斜度
                            },
                            axisTick:{
                                show:false // 不显示坐标轴刻度线
                            },
                            axisLine: {
                                lineStyle: {
                                    color: '#fff', //x轴的颜色
                                    width: 0, //轴线的宽度
                                }
                            },
                            splitLine: {
                                show: true,
                                lineStyle:{
                                    color: ['#1e8f63'],
                                    width: 1,
                                    type: 'solid'
                                }
                            }
                        },
                        series: [
                            {
                                name: '当季营收',
                                type: 'bar',
                                data: this.listData1,
                                barWidth: 12,
                                itemStyle: {
                                    normal: {
                                        //这里是颜色
                                        color: 'rgba(255,186,105,0.2)',
                                        // borderColor:'#FFBA69',
                                        borderWidth:2,
                                        shadowColor: 'rgba(255, 255, 0, 1)',
                                        shadowBlur: 12,
                                        borderColor: 'rgba(255, 186, 105, .7)',
                                    }
                                }
                            },
                            {
                                name: '累计营收',
                                type: 'bar',
                                data: this.listData2,
                                barWidth: 12,
                                itemStyle: {
                                    normal: {
                                        //这里是颜色
                                        color: 'rgba(15, 163, 121, .2)',
                                        // borderColor:'#FFBA69',
                                        borderWidth:2,
                                        shadowColor: 'rgba(83, 254, 192, 1)',
                                        shadowBlur: 12,
                                        borderColor: 'rgba(83, 254, 192, .7)',
                                    }
                                }
                            }
                        ]
                    })
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .revenue-statistic-wrap {
        padding-top: 10px;
        padding-left: 10px;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;
        .revenue-statistic-echars {
            height: 100%;
        }
    }
</style>
